import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import ModalWrapper from './ModalWrapper';

import {
    FlexWrapper,
    ButtonPrimary,
    Heading3,
    Modal,
    Input,
    Select,
    SelectWrapper,
    CloseIcon,
    ConfirmationWrapper,
    InputWrapper,
    RadioButtons,
    ModalError,
    FullscreenOverlay
} from '../StyledComponents';

import { CREATE_DATA_WIDGET, ADD_ITEM_TO_BOARD } from '../../graphql/queries';
import { ItemType } from '../../graphql/generated/graphql';
import { ItemRefNamed } from '../pages/Board';
import KPICheatsheet from "../elements/KPICheatsheet";

interface Props {
    closeModal: () => void;
    boardId: string;
    items: ItemRefNamed[];
    defaultOption1: string;
}

const CreateWidget: React.FC<Props> = (props: Props) => {
    const { closeModal, boardId, items, defaultOption1 } = props;

    // Common inputs
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    // Combined
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [operation, setOperation] = useState('+');

    const [createWidget] = useMutation(CREATE_DATA_WIDGET);
    const [updateBoard] = useMutation(ADD_ITEM_TO_BOARD);

    useEffect(() => {
        if (items.length > 0 && input1 === '') {
            setInput1(defaultOption1);
            setInput2(items[0].publicId);
        }
    }, [setInput1, setInput2, input1, items, defaultOption1]);

    const createMetric = () => {
        if (name.trim() !== '' && input1 !== '' && input2 !== '') {
            createWidget({
                variables: {
                    name,
                    publicId1: input1,
                    type1: items.filter((i) => i.publicId === input1)[0].itemType,
                    publicId2: input2,
                    type2: items.filter((i) => i.publicId === input2)[0].itemType,
                    operation: `$0${operation}$1`
                }
            })
                .then((res) => {
                    updateBoard({
                        variables: {
                            publicId: res.data.createItem.publicId,
                            boardId,
                            itemType: ItemType.Datawidget
                        }
                    }).then(() => {
                        setName('');
                        setInput1('');
                        setInput2('');
                        setOperation('+');
                        closeModal();
                    });
                })
                .catch((e) => console.log(e));
        } else {
            setError('Please fill out all fields before submitting.');
        }
    };

    const options = items.map(
        (i, index) =>
            !i.noValues && (
                <option key={i.publicId} value={i.publicId}>
                    {i.name ? i.name : index}
                </option>
            )
    );

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={closeModal}>
                <ConfirmationWrapper>
                    <Modal>
                        <CloseIcon onClick={closeModal} />

                        <Heading3>Combine metrics</Heading3>

                        <FlexWrapper col>
                            <InputWrapper>
                                <label>Title</label>
                                <Input
                                    placeholder='e.g. conversion rate, cost per acquisition'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </InputWrapper>

                            <FlexWrapper col>
                                <FlexWrapper>
                                    <SelectWrapper>
                                        <label>Metric 1</label>

                                        <Select
                                            value={input1}
                                            onChange={(e) => setInput1(e.target.value)}
                                        >
                                            {options}
                                        </Select>
                                    </SelectWrapper>

                                    <SelectWrapper>
                                        <label>Metric 2</label>

                                        <Select
                                            value={input2}
                                            onChange={(e) => setInput2(e.target.value)}
                                        >
                                            {options}
                                        </Select>
                                    </SelectWrapper>
                                </FlexWrapper>

                                <InputWrapper>
                                    <RadioButtons>
                                        <div>
                                            <input
                                                type='radio'
                                                name='operation'
                                                value='+'
                                                id='+'
                                                checked={operation === '+'}
                                                onChange={(e) => setOperation(e.target.value)}
                                            />
                                            <label htmlFor='+'>+</label>
                                        </div>
                                        <div>
                                            <input
                                                type='radio'
                                                name='operation'
                                                value='-'
                                                id='-'
                                                checked={operation === '-'}
                                                onChange={(e) => setOperation(e.target.value)}
                                            />
                                            <label htmlFor='-'>-</label>
                                        </div>
                                        <div>
                                            <input
                                                type='radio'
                                                name='operation'
                                                value='*'
                                                id='*'
                                                checked={operation === '*'}
                                                onChange={(e) => setOperation(e.target.value)}
                                            />
                                            <label htmlFor='*'>x</label>
                                        </div>
                                        <div>
                                            <input
                                                type='radio'
                                                name='operation'
                                                value='/'
                                                id='/'
                                                checked={operation === '/'}
                                                onChange={(e) => setOperation(e.target.value)}
                                            />
                                            <label htmlFor='/'>/</label>
                                        </div>
                                    </RadioButtons>
                                </InputWrapper>
                            </FlexWrapper>

                            {error.length > 0 && <ModalError>{error}</ModalError>}

                            <FlexWrapper stretch={false}>
                                <ButtonPrimary onClick={() => createMetric()}>Combine</ButtonPrimary>

                                <KPICheatsheet />
                            </FlexWrapper>
                        </FlexWrapper>
                    </Modal>
                </ConfirmationWrapper>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default CreateWidget;
