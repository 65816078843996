import React, { useState } from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import { Bookmark, BookmarkOutline } from 'react-ionicons';

interface Props {
    theme: DefaultTheme;
    onClick: () => void;
    active: boolean;
}

const ReportIcon: React.FC<Props> = ({ theme, onClick, active }) => {
    const [fill, setFill] = useState<boolean>(false);

    return (
        <div
            onMouseEnter={() => setFill(true)}
            onMouseLeave={() => setFill(false)}
            onClick={onClick}
        >
            {active || fill ? (
                <Bookmark
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.primary}
                    height='25px'
                    width='25px'
                />
            ) : (
                <BookmarkOutline
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.primary}
                    height='25px'
                    width='25px'
                />
            )}
        </div>
    );
};

export default withTheme(ReportIcon);
