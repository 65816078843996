import React from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import { Line, Bar } from 'react-chartjs-2';

import formatNumber from '../../util/formatNumber';

interface Props {
    name: string;
    unit: string;
    target:
        | {
              value: number;
              timestamp: number;
          }
        | undefined;
    values: {
        value: number;
        timestamp: number;
    }[];
    theme: DefaultTheme;
    highlighted?: boolean;
    line?: boolean;
}

const LineChart: React.FC<Props> = (props: Props) => {
    const { name, unit, theme, target, values, highlighted, line } = props;

    const options = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0
            }
        },
        legend: {
            display: false
        },
        title: {
            display: false
        },
        tooltips: {
            intersect: false,
            backgroundColor: theme.colors.white,
            titleFontFamily: theme.fontFamily,
            titleFontSize: 13,
            titleFontStyle: 'normal',
            titleFontColor: theme.colors.black,
            // titleAlign: 'center',
            titleSpacing: 5,
            bodyFontFamily: theme.fontFamily,
            bodyFontSize: 14,
            bodyFontStyle: 'bold',
            bodyFontColor: theme.colors.black,
            // bodyAlign: 'center',
            bodySpacing: 5,
            cornerRadius: 5,
            displayColors: false,
            borderWidth: 0,
            yPadding: 5,
            xPadding: 15,
            caretPadding: 20,
            callbacks: {
                label: (tooltipItem: any) => `${formatNumber(tooltipItem.value)}${unit}`
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
                borderWidth: 0,
                hoverRadius: 0,
                hitRadius: 0,
                hoverBorderWidth: 0
            },
            line: {
                tension: 0.3,
                borderWidth: 4,
                borderColor: highlighted
                    ? theme.darkTheme
                        ? theme.colors.white
                        : theme.colors.darkGrey
                    : theme.colors.primary,
                fill: true,
                borderCapStyle: 'round'
            }
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: true,
                        maxTicksLimit: 10,
                        fontSize: 16,
                        fontColor: theme.colors.middleGrey,
                        padding: 10
                    },
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        color: `${theme.colors.lightGrey}33`,
                        lineWidth: 2
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        display: true,
                        maxTicksLimit: 4,
                        fontSize: 14,
                        fontColor: theme.darkTheme
                            ? theme.colors.lightGrey
                            : theme.colors.middleGrey,
                        padding: 5,
                        beginAtZero: true,
                        callback: (value: any) => formatNumber(value) + unit
                    },
                    gridLines: {
                        display: false,
                        drawBorder: true
                    }
                }
            ]
        }
    };

    const data = (canvas: any) => {
        const chart = canvas.getContext('2d') || '';

        // Gradient for fill under graph
        let gradient;
        if (chart !== '') {
            gradient = chart.createLinearGradient(0, 0, 0, 400);

            if (highlighted) {
                if (theme.darkTheme) {
                    gradient.addColorStop(0, `${theme.colors.white}B0`);
                    gradient.addColorStop(1, `${theme.colors.lightGrey}00`);
                } else {
                    gradient.addColorStop(0, `${theme.colors.black}80`);
                    gradient.addColorStop(1, `${theme.colors.darkGrey}00`);
                }
            } else {
                if (theme.darkTheme) {
                    gradient.addColorStop(0, `${theme.colors.primary}F0`);
                    gradient.addColorStop(1, `${theme.colors.darkGrey}39`);
                } else {
                    gradient.addColorStop(0, `${theme.colors.primary}C0`);
                    gradient.addColorStop(1, `${theme.colors.darkGrey}69`);
                }
            }
        } else {
            gradient = `${theme.colors.primary}F0`;
        }

        // Create data + labels arrays from objects
        const data: Array<number> = [];
        const labels: Array<string> = [];

        values.map((v) => {
            data.push(parseFloat(v.value.toFixed(2)));
            return labels.push(new Date(v.timestamp * 1000).toLocaleDateString());
        });

        // Create array with all values being the target value for straight target line
        const targetArray: Array<number> = [];
        if (target) data.map(() => targetArray.push(target.value));

        return {
            labels,
            datasets: line
                ? [
                      {
                          label: name,
                          data,
                          pointBackgroundColor: 'transparent',
                          pointHoverBackgroundColor: theme.colors.white,
                          pointBorderColor: 'transparent',
                          pointHoverBorderColor: theme.colors.primary,
                          backgroundColor: gradient
                      },
                      {
                          label: 'Target',
                          data: targetArray,
                          steppedLine: true,
                          pointRadius: 0,
                          pointHoverRadius: 0,
                          fill: false,
                          borderWidth: 2,
                          borderColor: theme.colors.error,
                          borderDash: [7],
                          pointHitRadius: 0
                      }
                  ]
                : [
                      {
                          label: name,
                          data,
                          pointBackgroundColor: 'transparent',
                          pointHoverBackgroundColor: theme.colors.white,
                          pointBorderColor: 'transparent',
                          pointHoverBorderColor: theme.colors.primary,
                          backgroundColor: `${theme.colors.primary}C0`
                      }
                  ]
        };
    };

    return (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
            {line ? <Line data={data} options={options} /> : <Bar data={data} options={options} />}
        </div>
    );
};

// @ts-ignore
export default withTheme(LineChart);
