import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Link, Redirect, useParams } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import update from 'immutability-helper';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
    ButtonPrimary,
    Container,
    FlexWrapper,
    EditableHeading2,
    EditableParagraph,
    Header,
    HeaderLink,
    HeaderLinkWrapper,
    DateRangeWrapper
} from '../StyledComponents';

import CreateMetric from '../modals/CreateMetric';
import CreateWidget from '../modals/CreateWidget';
import CopyMetric from '../modals/MoveMetric';
import EmailModal from '../modals/EmailModal';
import Grid from '../layout/Grid';
import Loading from '../elements/Loading';

import {
    GET_BOARD,
    ITEM_NAMES,
    UPDATE_LAYOUT,
    UPDATE_METRIC_VIEW,
    UPDATE_BOARD_INFO
} from '../../graphql/queries';
import { REAL_TIME_BOARD, SEND_REPORT, INVITE_OTHERS } from '../../graphql/queries';
import { ItemType, ItemRef } from '../../graphql/generated/graphql';
import Feedback from '../elements/Feedback';

interface Props {
    showWarning: (warning: any) => void;
}

export interface ItemRefNamed extends ItemRef {
    name?: string;
    noValues?: boolean;
}

const Board: React.FC<Props> = (props) => {
    const { id: publicId } = useParams<{ id: string }>();

    const { showWarning } = props;

    // Modal states
    const [addModal, showAddModal] = useState<boolean>(false);
    const [combineModal, showCombineModal] = useState<boolean>(false);
    const [combineModalTrigger, setCombineModalTrigger] = useState<string>('');
    const [moveModal, showMoveModal] = useState<boolean>(false);
    const [moveModalTrigger, setMoveModalTrigger] = useState<any>(null);
    const [showInvite, setShowInvite] = useState<boolean>(false);
    const [showSendReport, setShowSendReport] = useState<boolean>(false);

    // Controlled inputs (title, description, date range)
    const [boardTitle, setBoardTitle] = useState<string>('');
    const [boardDescription, setBoardDescription] = useState<string>('');
    const [dateRange, setDateRange] = useState<any>({
        startDate: null,
        endDate: null
    });
    const [dateRangeFocused, setDateRangeFocused] = useState<'startDate' | 'endDate' | null>(null);

    // Board items with names for combine modal
    const [boardItems, setBoardItems] = useState<ItemRefNamed[]>([]);

    const [updateBoardLayout] = useMutation(UPDATE_LAYOUT);
    const [updateMetricViewMutation] = useMutation(UPDATE_METRIC_VIEW);
    const [updateBoardInfo] = useMutation(UPDATE_BOARD_INFO);
    const [sendReportMutation] = useMutation(SEND_REPORT);
    const [inviteOthersMutation] = useMutation(INVITE_OTHERS);

    const { loading, error, data, refetch, subscribeToMore } = useQuery(GET_BOARD, {
        variables: { publicId }
    });
    const [queryItemNames, { data: nameData }] = useLazyQuery(ITEM_NAMES);

    const updateLayout = async (boardId: string, layouts: string) => {
        await updateBoardLayout({ variables: { boardId, layouts } })
            .then(() => refetch())
            .catch((e) => console.log(e));
    };

    const updateMetricView = async (
        boardId: string,
        publicId: string,
        renderSelection: string,
        itemType: ItemType,
        inReport: boolean,
        displayMode: string
    ) => {
        await updateMetricViewMutation({
            variables: { boardId, publicId, renderSelection, itemType, inReport, displayMode }
        })
            .then(() => refetch())
            .catch((e) => console.log(e));
    };

    const updateInfo = (boardId: string, dateFilter?: any) => {
        let variables: any = {
            publicId: boardId,
            name: boardTitle,
            description: boardDescription
        };

        if (dateFilter) {
            const { startDate, endDate } = dateFilter;

            if (startDate !== null && endDate !== null) {
                const min = moment(startDate).toDate().getTime() / 1000;
                const max = moment(endDate).toDate().getTime() / 1000;

                variables.dateFilter = { startDate: min, endDate: max };
            }
        }

        updateBoardInfo({
            variables
        })
            .then(() => refetch())
            .catch((e) => console.log(e));
    };

    const inviteOthers = (emails: string[], callback: () => void) => {
        inviteOthersMutation({ variables: { boardId: data.board.publicId, emails } })
            .then(() => callback())
            .catch((e) => console.log(e));
    };

    const sendReport = (emails: string[], callback: () => void) => {
        sendReportMutation({ variables: { boardId: data.board.publicId, emails } })
            .then(() => {
                callback();
                refetch().then(() => {});
            })
            .catch((e) => console.log(e));
    };

    const triggerCombineModal = (triggerPublicId: string) => {
        // Only allow if at least 2 metrics exist
        if (items.length < 2) {
            showWarning({
                title: 'Warning',
                message: 'You need at least 2 metrics to be able to combine them.',
                button: 'Ok',
                action: () => ''
            });
        } else {
            showCombineModal(true);
            setCombineModalTrigger(triggerPublicId);
        }
    };

    const triggerMoveModal = (publicId: string, itemType: string) => {
        showMoveModal(true);
        setMoveModalTrigger({ publicId, itemType });
    };

    useEffect(() => {
        if (data && data.board) {
            setBoardTitle(data.board.name);
            setBoardDescription(data.board.description);
            setDateRange({
                startDate:
                    data.board.dateFilter.startDate !== null
                        ? moment(data.board.dateFilter.startDate * 1000)
                        : null,
                endDate:
                    data.board.dateFilter.endDate !== null
                        ? moment(data.board.dateFilter.endDate * 1000)
                        : null
            });

            queryItemNames({
                variables: {
                    itemIdentifiers: data.board.items.map((item: ItemRef) => ({
                        publicId: item.publicId,
                        itemType: item.itemType
                    }))
                }
            });
        }
    }, [data, queryItemNames]);

    useEffect(() => {
        if (nameData && nameData.items) {
            setBoardItems(
                data.board.items.map((x: ItemRef) => ({
                    ...x,
                    ...nameData.items.find((y: ItemRefNamed) => y.publicId === x.publicId)
                }))
            );
        }
    }, [nameData, data]);

    useEffect(() => {
        if (subscribeToMore && data && data.board) {
            const unsubRealTimeBoard = subscribeToMore({
                document: REAL_TIME_BOARD,
                variables: { publicId: data.board.publicId },
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;

                    setBoardTitle(subscriptionData.data.itemUpdated.name);
                    setBoardDescription(subscriptionData.data.itemUpdated.description);

                    return update(prev, { board: { $merge: subscriptionData.data.itemUpdated } });
                }
            });

            return () => {
                unsubRealTimeBoard();
            };
        }
    });

    if (loading || (data && data.board && data.board.items.length > 0 && boardItems.length === 0)) {
        return (
            <Container style={{ padding: 50 }}>
                <Loading />
            </Container>
        );
    } else if (error) {
        return (
            <Container style={{ padding: 50, textAlign: 'center' }}>
                <p>Error... Please try reloading the page.</p>
            </Container>
        );
    } else if (data && data.board === null) {
        return <Redirect to='/' />;
    }

    const {
        board: { items, layouts }
    } = data;

    return (
        <>
            <ReactTooltip
                type={
                    window.localStorage.getItem('theme') &&
                    window.localStorage.getItem('theme') === 'light'
                        ? 'dark'
                        : 'light'
                }
            />

            <Container>
                <Header actions>
                    <FlexWrapper col>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateInfo(publicId);
                            }}
                        >
                            <EditableHeading2
                                data-tip='Click to edit'
                                data-delay-show={300}
                                value={boardTitle}
                                onChange={(e) => setBoardTitle(e.target.value)}
                                onBlur={(e) => {
                                    e.preventDefault();
                                    updateInfo(publicId);
                                }}
                            />
                        </form>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateInfo(publicId);
                            }}
                        >
                            <EditableParagraph
                                data-tip='Click to edit'
                                data-delay-show={300}
                                value={boardDescription}
                                onChange={(e) => setBoardDescription(e.target.value)}
                                onBlur={(e) => {
                                    e.preventDefault();
                                    updateInfo(publicId);
                                }}
                            />
                        </form>
                    </FlexWrapper>

                    <HeaderLinkWrapper>
                        {items.filter((i: any) => i.inReport).length > 0 ? (
                            <Link to={`/report/${publicId}`}>
                                <HeaderLink>View report</HeaderLink>
                            </Link>
                        ) : (
                            <HeaderLink
                                onClick={() =>
                                    showWarning({
                                        title: 'Warning',
                                        message:
                                            'Please add at least one metric to the report to be able to view it.',
                                        button: 'Ok',
                                        action: () => ''
                                    })
                                }
                            >
                                View report
                            </HeaderLink>
                        )}
                        {items.filter((i: any) => i.inReport).length > 0 ? (
                            <HeaderLink onClick={() => setShowSendReport(!showSendReport)}>
                                Send report
                            </HeaderLink>
                        ) : (
                            <HeaderLink
                                onClick={() =>
                                    showWarning({
                                        title: 'Warning',
                                        message:
                                            'Please add at least one metric to the report to be able to share it.',
                                        button: 'Ok',
                                        action: () => ''
                                    })
                                }
                            >
                                Send report
                            </HeaderLink>
                        )}
                        <HeaderLink onClick={() => setShowInvite(!showInvite)}>
                            Invite collaborators
                        </HeaderLink>

                        <DateRangeWrapper>
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                startDateId='your_unique_start_date_id'
                                endDate={dateRange.endDate}
                                endDateId='your_unique_end_date_id'
                                onDatesChange={({ startDate, endDate }) => {
                                    setDateRange({ startDate, endDate });
                                    updateInfo(publicId, { startDate, endDate });
                                }}
                                focusedInput={dateRangeFocused}
                                onFocusChange={(focusedInput) => setDateRangeFocused(focusedInput)}
                                numberOfMonths={2}
                                isOutsideRange={() => false}
                                hideKeyboardShortcutsPanel={true}
                                minimumNights={0}
                            />
                        </DateRangeWrapper>

                        <ButtonPrimary onClick={() => showAddModal(!addModal)} plusIcon>
                            Add new metric
                        </ButtonPrimary>
                    </HeaderLinkWrapper>
                </Header>

                <Grid
                    items={items}
                    boardId={publicId}
                    layoutsString={layouts}
                    updateMetricView={updateMetricView}
                    updateLayout={updateLayout}
                    showWarning={showWarning}
                    triggerCombineModal={triggerCombineModal}
                    triggerMoveModal={triggerMoveModal}
                    dateRange={dateRange}
                />

                {addModal && (
                    <CreateMetric
                        closeModal={() => {
                            showAddModal(false);
                            refetch().then(() => {});
                        }}
                        boardId={publicId}
                        items={boardItems!}
                    />
                )}

                {combineModal && (
                    <CreateWidget
                        closeModal={() => {
                            showCombineModal(false);
                            refetch();
                        }}
                        boardId={publicId}
                        items={boardItems!}
                        defaultOption1={combineModalTrigger}
                    />
                )}

                {moveModal && (
                    <CopyMetric
                        closeModal={() => showMoveModal(false)}
                        boardId={publicId}
                        item={moveModalTrigger}
                        refetch={refetch}
                    />
                )}

                {showInvite && (
                    <EmailModal
                        title='Invite collaborators'
                        buttonText='Invite'
                        closeModal={() => setShowInvite(false)}
                        action={inviteOthers}
                    />
                )}

                {showSendReport && (
                    <EmailModal
                        title='Send report'
                        buttonText='Share'
                        closeModal={() => setShowSendReport(false)}
                        action={sendReport}
                    />
                )}
            </Container>

            <Feedback />
        </>
    );
};

export default Board;
