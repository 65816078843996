import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import update from 'immutability-helper';

import Metric from './Metric';

import { CHANGE_ITEM_TITLE, GET_METRIC, REAL_TIME_ITEM } from '../../graphql/queries';

import { ItemType } from '../../graphql/generated/graphql';

interface Props {
    publicId: string;
    boardId: string;
    renderSelection: string;
    inReport: boolean;
    displayMode: string;
    dateRange: { startDate: number | null; endDate: number | null };
    updateMetricView: (
        boardId: string,
        publicId: string,
        renderSelection: string,
        itemType: ItemType,
        inReport: boolean,
        displayMode: string
    ) => void;
    showWarning: (warning: any) => void;
    setDraggable: (v: boolean) => void;
    triggerCombineModal: (triggerPublicId: string) => void;
    triggerMoveModal: (publicId: string, itemType: string) => void;
}

const Item: React.FC<Props> = (props: Props) => {
    const { publicId } = props;

    const [title, setTitle] = useState<string>('');

    const [updateItemTitle] = useMutation(CHANGE_ITEM_TITLE);

    const { loading, error, data, subscribeToMore, refetch } = useQuery(GET_METRIC, {
        variables: { publicId }
    });

    const updateTitle = () => {
        updateItemTitle({
            variables: { publicId, newTitle: title }
        }).catch((e) => console.log(e));
    };

    useEffect(() => {
        if (subscribeToMore && data && data.item) {
            const unsubItem = subscribeToMore({
                document: REAL_TIME_ITEM,
                variables: { publicIds: [publicId] },
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;

                    setTitle(subscriptionData.data.itemUpdated.name);

                    return update(prev, { item: { $merge: subscriptionData.data.itemUpdated } });
                }
            });

            return () => {
                unsubItem();
            };
        }
    });

    return (
        <Metric
            {...props}
            itemType={ItemType.Dataitem}
            loading={loading}
            title={title}
            updateTitle={updateTitle}
            setTitle={setTitle}
            error={error}
            data={data}
            refetch={refetch}
        />
    );
};

export default Item;
