import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import ModalWrapper from './ModalWrapper';
import Loading from '../elements/Loading';

import {
    FlexWrapper,
    ButtonPrimary,
    Heading3,
    Modal,
    InputWrapper,
    Select,
    SelectWrapper,
    CloseIcon,
    ConfirmationWrapper,
    ModalError,
    FullscreenOverlay
} from '../StyledComponents';

import { ADD_ITEM_TO_BOARD, REMOVE_METRIC_FROM_BOARD, GET_BOARDS } from '../../graphql/queries';

interface Props {
    closeModal: () => void;
    boardId: string; // publicId of the current board
    item: any;
    refetch: () => void; // refetch of the board, sometimes board subscription doesn't update board when metric is removed
}

const MoveMetric: React.FC<Props> = (props: Props) => {
    const { closeModal, boardId, item, refetch } = props;

    const [newBoard, setNewBoard] = useState<string>(''); // publicId of board to copy to
    const [move, setMove] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { loading, error, data } = useQuery(GET_BOARDS);

    const [addToBoard] = useMutation(ADD_ITEM_TO_BOARD);
    const [removeMetric] = useMutation(REMOVE_METRIC_FROM_BOARD);

    const copyMetric = () => {
        if (newBoard !== '') {
            const { publicId, itemType } = item;

            // Add metric to new board
            addToBoard({
                variables: {
                    publicId,
                    boardId: newBoard,
                    itemType
                }
            })
                .then(() => {
                    if (!move) {
                        // Remove metric from current board
                        removeMetric({
                            variables: {
                                publicId,
                                boardId
                            }
                        }).then(() => {
                            refetch();
                            closeModal();
                        });
                    } else {
                        closeModal();
                    }
                })
                .catch(() => setErrorMessage('Error... Please try reloading the page.'));
        } else {
            setErrorMessage('Please choose a board to move to.');
        }
    };

    let content;

    if (loading) content = <Loading />;

    if (error) content = <p>Error... Try reloading the page.</p>;

    if (newBoard === '') setNewBoard(data.user.boards[0].publicId);

    content = (
        <FlexWrapper col>
            <SelectWrapper>
                <label style={{ marginBottom: 10 }}>Select the destination board</label>

                <Select value={newBoard} onChange={(e) => setNewBoard(e.target.value)}>
                    {data.user.boards.map(
                        (b: any, index: number) =>
                            b.publicId !== boardId && (
                                <option key={b.publicId} value={b.publicId}>
                                    {b.name ? b.name : index}
                                </option>
                            )
                    )}
                </Select>
            </SelectWrapper>

            <InputWrapper>
                <input
                    type='checkbox'
                    id='moveCheckbox'
                    checked={move}
                    onChange={(e) => setMove(e.target.checked)}
                />
                <label
                    htmlFor='moveCheckbox'
                    style={{ display: 'inline-block', marginLeft: 10, marginBottom: 20 }}
                >
                    Keep a synchronized copy in this board
                </label>
            </InputWrapper>

            {errorMessage.length > 0 && <ModalError>{errorMessage}</ModalError>}

            <ButtonPrimary onClick={() => copyMetric()}>Move</ButtonPrimary>
        </FlexWrapper>
    );

    return (
        <FullscreenOverlay>
            <ModalWrapper onClose={closeModal}>
                <ConfirmationWrapper>
                    <Modal>
                        <CloseIcon onClick={closeModal} />

                        <Heading3>Move metric</Heading3>

                        {content}
                    </Modal>
                </ConfirmationWrapper>
            </ModalWrapper>
        </FullscreenOverlay>
    );
};

export default MoveMetric;
