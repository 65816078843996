import React from 'react';

import ModalWrapper from './ModalWrapper';

import {
    ConfirmationWrapper,
    ConfirmationMessage,
    ButtonPrimary,
    ButtonError,
    Heading3,
    Modal,
    CloseIcon,
    FullscreenOverlay
} from '../StyledComponents';

interface Props {
    title: string;
    message: string;
    button: string;
    closeModal: () => void;
    action: () => void;
    error: boolean;
}

const Confirmation: React.FC<Props> = (props: Props) => {
    const { title, message, button, error, closeModal, action } = props;

    return (
        <>
            <FullscreenOverlay />
            <ModalWrapper onClose={closeModal}>
                <ConfirmationWrapper>
                    <Modal>
                        <CloseIcon onClick={() => closeModal()} />
                        <Heading3>{title}</Heading3>

                        <ConfirmationMessage>{message}</ConfirmationMessage>

                        {error ? (
                            <ButtonError
                                onClick={() => {
                                    action();
                                    closeModal();
                                }}
                            >
                                {button}
                            </ButtonError>
                        ) : (
                            <ButtonPrimary
                                onClick={() => {
                                    action();
                                    closeModal();
                                }}
                            >
                                {button}
                            </ButtonPrimary>
                        )}
                    </Modal>
                </ConfirmationWrapper>
            </ModalWrapper>
        </>
    );
};

export default Confirmation;
