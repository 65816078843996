import React from 'react';
import Loader from 'react-spinners/RingLoader';
import { withTheme, DefaultTheme } from 'styled-components';

import { LoadingWrapper } from '../StyledComponents';

const Loading: React.FC<{ theme: DefaultTheme }> = ({ theme }) => (
    <LoadingWrapper>
        <Loader loading={true} color={theme.colors.lightGrey} size={75} />
    </LoadingWrapper>
);

export default withTheme(Loading);
