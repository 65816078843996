import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import Big from 'big.js';

import LineChart from './LineChart';
import Loading from './Loading';
import ModalWrapper from '../modals/ModalWrapper';

import TargetIcon from '../../assets/trophy.svg';
import formatNumber from '../../util/formatNumber';

import {
    Card,
    EditableHeading4,
    FlexWrapper,
    Input,
    MetricValue,
    MetricNoValue,
    MetricChange,
    MetricTargetValue,
    ButtonPrimary,
    OptionsMenu,
    ProgressBar,
    VerticalDots,
    MetricTop,
    DisplayMode,
    AddValue,
    MetricProgress,
    MetricTargetIcon,
    MetricTarget,
    MetricNoTarget,
    ProgressBarWrapper
} from '../StyledComponents';
import ReportIcon from './ReportIcon';

import {
    ADD_VALUE,
    EDIT_TARGET_ITEM,
    EDIT_TARGET_WIDGET,
    DELETE_TARGET_ITEM,
    DELETE_TARGET_WIDGET,
    EDIT_UNIT_ITEM,
    REMOVE_METRIC_FROM_BOARD
} from '../../graphql/queries';

import { ItemType } from '../../graphql/generated/graphql';
import percIncrease from '../../util/percIncrease';
import progress from '../../util/progress';
import sameDay from '../../util/sameDay';

interface Props {
    loading: boolean;
    error: any;
    data: any;
    title: string;
    publicId: string;
    boardId: string;
    renderSelection: string;
    inReport: boolean;
    displayMode: string;
    dateRange: { startDate: number | null; endDate: number | null };
    itemType: ItemType;
    values?: any;
    updateMetricView: (
        boardId: string,
        publicId: string,
        renderSelection: string,
        itemType: ItemType,
        inReport: boolean,
        displayMode: string
    ) => void;
    showWarning: (warning: any) => void;
    setDraggable: (v: boolean) => void;
    triggerCombineModal: (triggerPublicId: string) => void;
    triggerMoveModal: (publicId: string, itemType: string) => void;
    updateTitle: () => void;
    setTitle: (p: any) => void;
    refetch: () => void;
}

const Metric: React.FC<Props> = (props: Props) => {
    const {
        publicId,
        boardId,
        renderSelection,
        displayMode,
        inReport,
        dateRange,
        itemType,
        title,
        loading,
        error,
        data,
        values: widgetValues,
        updateMetricView,
        showWarning,
        setDraggable,
        triggerCombineModal,
        triggerMoveModal,
        updateTitle,
        setTitle,
        refetch
    } = props;

    const now = new Date();
    const today = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;

    const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
    const [addValueOpen, setAddValueOpen] = useState<boolean>(false);
    const [editTargetOpen, setEditTargetOpen] = useState<boolean>(false);
    const [editUnitOpen, setEditUnitOpen] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<string>('');
    const [newDate, setNewDate] = useState(today);
    const [newTarget, setNewTarget] = useState<string>('');
    const [newTargetDate, setNewTargetDate] = useState(today);
    const [newUnit, setNewUnit] = useState<string>('');
    const [openTarget, setOpenTarget] = useState<boolean>(false);

    const [removeMetric] = useMutation(REMOVE_METRIC_FROM_BOARD);
    const [addValue] = useMutation(ADD_VALUE);
    const [editTargetItem] = useMutation(EDIT_TARGET_ITEM);
    const [editTargetWidget] = useMutation(EDIT_TARGET_WIDGET);
    const [deleteTargetItem] = useMutation(DELETE_TARGET_ITEM);
    const [deleteTargetWidget] = useMutation(DELETE_TARGET_WIDGET);
    const [editUnit] = useMutation(EDIT_UNIT_ITEM);

    const editTarget = itemType === ItemType.Datawidget ? editTargetWidget : editTargetItem;
    const deleteTarget = itemType === ItemType.Datawidget ? deleteTargetWidget : deleteTargetItem;

    const updateView = (
        changeView: boolean,
        changeReport: boolean,
        changeMode: boolean = false
    ) => {
        updateMetricView(
            boardId,
            publicId,
            changeView ? (renderSelection === 'SIMPLE' ? 'LINEGRAPH' : 'SIMPLE') : renderSelection,
            itemType,
            changeReport ? !inReport : inReport,
            changeMode ? (displayMode === 'SINGLE' ? 'SUM' : 'SINGLE') : displayMode
        );

        setOptionsOpen(false);
    };

    const addNewValue = () => {
        if (newValue.trim() !== '' && newDate !== '') {
            let exists = false;
            let existingValue: any;

            // Copy old values array (bc it's not writable somehow)
            const newValues = values.map((v: any) => {
                // Overwrite if there is already a value for the given day
                if (sameDay(new Date(v.timestamp * 1000), new Date(newDate))) {
                    exists = true;
                    existingValue = v;
                    return { timestamp: v.timestamp, value: parseFloat(newValue) };
                }
                return { value: v.value, timestamp: v.timestamp };
            });

            if (!exists)
                newValues.push({
                    value: parseFloat(newValue),
                    timestamp: new Date(newDate).getTime() / 1000
                });

            newValues.sort(
                (a: { timestamp: number }, b: { timestamp: number }) => a.timestamp - b.timestamp
            );

            if (exists && existingValue !== undefined) {
                showWarning({
                    title: 'Warning',
                    message: `Do you really want to change the value for ${new Date(
                        existingValue.timestamp * 1000
                    ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })} from ${existingValue.value}${unit} to ${newValue}${unit}?`,
                    button: 'Confirm',
                    action: () => {
                        addValue({ variables: { publicId, values: newValues } })
                            .then(() => {
                                setAddValueOpen(false);
                                setNewValue('');
                                setNewDate(today);
                                refetch();
                            })
                            .catch((e) => console.log(e));
                    }
                });
            } else {
                addValue({ variables: { publicId, values: newValues } })
                    .then(() => {
                        setAddValueOpen(false);
                        setNewValue('');
                        setNewDate(today);
                        refetch();
                    })
                    .catch((e) => console.log(e));
            }
        }
    };

    const updateTarget = (remove?: boolean) => {
        if (remove) {
            deleteTarget({
                variables: {
                    publicId
                }
            })
                .then(() => {
                    setEditTargetOpen(false);
                    refetch();
                })
                .catch((e) => console.log(e));
        } else if (newTarget.trim() !== '' && newTargetDate !== '') {
            editTarget({
                variables: {
                    publicId,
                    value: parseFloat(newTarget),
                    dueDate: new Date(newTargetDate).getTime() / 1000
                }
            })
                .then(() => {
                    setEditTargetOpen(false);
                    refetch();
                })
                .catch((e) => console.log(e));
        }
    };

    const updateUnit = () => {
        editUnit({
            variables: {
                publicId,
                unit: newUnit.length === 0 ? ' ' : newUnit
            }
        })
            .then(() => {
                setEditUnitOpen(false);
                refetch();
            })
            .catch((e) => console.log(e));
    };

    const deleteMetric = () => {
        showWarning({
            title: 'Warning',
            message: 'This metric will permanently be removed from the board.',
            button: 'Confirm',
            error: true,
            action: () => {
                removeMetric({ variables: { publicId, boardId } }).catch((e) => console.log(e));
            }
        });
    };

    useEffect(() => {
        if (data && data.item) {
            const { name, target } = data.item;

            setTitle(name);

            if (target && target.value) {
                setNewTarget(target.value);
                setNewTargetDate(new Date(target.dueDate * 1000).toISOString().split('T')[0]);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    if (loading)
        return (
            <Card>
                <Loading />
            </Card>
        );

    if (error || data.item === null) {
        return (
            <Card>
                <p>Error</p>
            </Card>
        );
    }

    const { name, target } = data.item;
    const values = itemType === ItemType.Datawidget ? widgetValues : data.item.values;
    const unit = itemType === ItemType.Datawidget ? '' : data.item.unit;

    let renderValues = values.map((v: any) => ({ ...v }));

    // Only show values in specified time interval
    renderValues = renderValues.filter((v: any) => {
        const { startDate, endDate } = dateRange;

        if (startDate !== null && endDate !== null) {
            const min = moment(startDate).startOf('day').toDate().getTime();
            const max = moment(endDate).endOf('day').toDate().getTime();

            return v.timestamp * 1000 >= min && v.timestamp * 1000 <= max;
        } else {
            return true;
        }
    });

    if (displayMode === 'SUM') {
        let sum = new Big(0);
        renderValues = renderValues.map((v: { value: number }) => {
            sum = sum.add(v.value);
            return { ...v, value: sum.toNumber() };
        });
    }

    return (
        <Card>
            <FlexWrapper col>
                <MetricTop>
                    <ReportIcon onClick={() => updateView(false, true)} active={inReport} />

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateTitle();
                        }}
                    >
                        <EditableHeading4
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onMouseEnter={() => setDraggable(false)}
                            onMouseLeave={() => setDraggable(true)}
                            onBlur={(e) => {
                                e.preventDefault();
                                updateTitle();
                            }}
                        />
                        <DisplayMode>
                            {displayMode === 'SINGLE'
                                ? renderSelection === 'SIMPLE'
                                    ? 'Last entry'
                                    : 'Single values'
                                : 'All-time sum'}
                        </DisplayMode>
                    </form>
                    <FlexWrapper>
                        {itemType === ItemType.Dataitem && (
                            <AddValue
                                onClick={() => {
                                    setOptionsOpen(false);
                                    setAddValueOpen(true);
                                }}
                            >
                                +
                            </AddValue>
                        )}
                        <VerticalDots onClick={() => setOptionsOpen(true)} />
                    </FlexWrapper>
                </MetricTop>

                {renderSelection === 'SIMPLE' ? (
                    <FlexWrapper col>
                        {renderValues.length > 0 ? (
                            <MetricValue>
                                {formatNumber(renderValues[renderValues.length - 1].value)}
                                {unit}
                            </MetricValue>
                        ) : (
                            <MetricNoValue>
                                {itemType === ItemType.Dataitem
                                    ? 'Click the plus icon to add a value ↗️'
                                    : 'Add more values in the inputs'}
                            </MetricNoValue>
                        )}

                        <MetricProgress
                            onMouseEnter={() => setOpenTarget(true)}
                            onMouseLeave={() => setOpenTarget(false)}
                        >
                            <MetricChange
                                down={percIncrease(renderValues) < 0}
                                equal={percIncrease(renderValues) === 0}
                                visible={
                                    renderValues.length > 0 &&
                                    (!target || !target.value || !openTarget)
                                }
                            >
                                <span />
                                {percIncrease(renderValues)}% since last entry{' '}
                                {renderValues.length > 1 &&
                                    `(${renderValues[renderValues.length - 2].value}${unit})`}
                            </MetricChange>

                            {target && target.value ? (
                                <>
                                    <ProgressBarWrapper moveToTop={openTarget}>
                                        <ProgressBar percentage={progress(renderValues, target)}>
                                            <div>
                                                <span />
                                            </div>
                                        </ProgressBar>
                                        {openTarget ? (
                                            <p>{progress(renderValues, target)}%</p>
                                        ) : (
                                            <MetricTargetIcon>
                                                <img src={TargetIcon} alt='Target' />
                                            </MetricTargetIcon>
                                        )}
                                    </ProgressBarWrapper>

                                    <MetricTarget visible={openTarget}>
                                        <MetricTargetValue>
                                            <img src={TargetIcon} alt='Target' />
                                            {formatNumber(target.value)}
                                            {unit}
                                        </MetricTargetValue>
                                        <p>
                                            by{' '}
                                            {new Date(target.dueDate * 1000).toLocaleDateString(
                                                'en-US',
                                                {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                }
                                            )}
                                        </p>
                                    </MetricTarget>
                                </>
                            ) : (
                                <MetricNoTarget>Add a target to track your progress</MetricNoTarget>
                            )}
                        </MetricProgress>
                    </FlexWrapper>
                ) : (
                    <LineChart
                        name={name}
                        unit={unit}
                        values={renderValues}
                        target={target}
                        line={displayMode !== 'SINGLE'}
                    />
                )}
            </FlexWrapper>

            <ModalWrapper onClose={() => setOptionsOpen(false)}>
                <OptionsMenu
                    show={optionsOpen}
                    onMouseEnter={() => setDraggable(false)}
                    onMouseLeave={() => setDraggable(true)}
                >
                    <p onClick={() => updateView(false, false, true)}>
                        {displayMode === 'SINGLE' ? 'Show all time' : 'Show single values'}
                    </p>
                    <p onClick={() => triggerCombineModal(publicId)}>Combine metric</p>
                    <p onClick={() => triggerMoveModal(publicId, ItemType.Dataitem)}>Move metric</p>
                    {itemType === ItemType.Dataitem && (
                        <p
                            onClick={() => {
                                setOptionsOpen(false);
                                setEditUnitOpen(true);
                            }}
                        >
                            {unit === '' ? 'Add unit' : 'Edit unit'}
                        </p>
                    )}
                    <p
                        onClick={() => {
                            setOptionsOpen(false);
                            setEditTargetOpen(true);
                        }}
                    >
                        {target && target.value ? 'Update target' : 'Add target'}
                    </p>
                    <p onClick={deleteMetric}>Delete</p>
                </OptionsMenu>
            </ModalWrapper>

            <ModalWrapper onClose={() => setAddValueOpen(false)}>
                <OptionsMenu
                    show={addValueOpen}
                    onSubmit={(e) => {
                        e.preventDefault();
                        addNewValue();
                    }}
                    onMouseEnter={() => setDraggable(false)}
                    onMouseLeave={() => setDraggable(true)}
                >
                    <Input
                        placeholder='Add value'
                        type='number'
                        step='.01'
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        small
                    />
                    <Input
                        type='date'
                        placeholder='Date of start value'
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                        small
                    />
                    <ButtonPrimary small>Save</ButtonPrimary>
                </OptionsMenu>
            </ModalWrapper>

            <ModalWrapper onClose={() => setEditTargetOpen(false)}>
                <OptionsMenu
                    show={editTargetOpen}
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateTarget();
                    }}
                    onMouseEnter={() => setDraggable(false)}
                    onMouseLeave={() => setDraggable(true)}
                >
                    <Input
                        placeholder='New target'
                        type='number'
                        step='.01'
                        value={newTarget}
                        onChange={(e) => setNewTarget(e.target.value)}
                        small
                    />
                    <Input
                        type='date'
                        placeholder='Target date'
                        value={newTargetDate}
                        onChange={(e) => setNewTargetDate(e.target.value)}
                        small
                    />
                    <FlexWrapper>
                        <ButtonPrimary small>Save</ButtonPrimary>
                        <span style={{ cursor: 'pointer' }} onClick={() => updateTarget(true)}>
                            Delete
                        </span>
                    </FlexWrapper>
                </OptionsMenu>
            </ModalWrapper>

            <ModalWrapper onClose={() => setEditUnitOpen(false)}>
                <OptionsMenu
                    show={editUnitOpen}
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateUnit();
                    }}
                    onMouseEnter={() => setDraggable(false)}
                    onMouseLeave={() => setDraggable(true)}
                >
                    <Input
                        placeholder='e.g. €, % (or leave empty for quantities)'
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                        small
                    />
                    <FlexWrapper>
                        <ButtonPrimary small>Save</ButtonPrimary>
                    </FlexWrapper>
                </OptionsMenu>
            </ModalWrapper>
        </Card>
    );
};

export default Metric;
