// Calculate percentage increase/ decrease between last 2 values
function percIncrease(values: { value: number }[]) {
    if (values.length < 2) return 0;

    const a = values[values.length - 2].value;
    const b = values[values.length - 1].value;

    let percent;

    if (b !== 0) {
        if (a !== 0) {
            percent = ((b - a) / a) * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = -a * 100;
    }

    return Math.floor(percent);
}

export default percIncrease;
