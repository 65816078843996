import React, { useState } from 'react';

import { Heading4, Cheatsheet, SmallIcon } from '../StyledComponents';
import ModalWrapper from '../modals/ModalWrapper';

const KPICheatsheet: React.FC = () => {
    const [visible, setVisibility] = useState<boolean>(false);

    return (
        <>
            <ModalWrapper onClose={() => setVisibility(false)}>
                <Cheatsheet visible={visible}>
                    <Heading4>KPI cheatsheet</Heading4>
                    <p>
                        <span>CTR (click through rate)</span># of clicks/ # of impressions
                    </p>
                    <p>
                        <span>Conversion rate</span> # of conversions/ # of interactions
                    </p>
                    <p>
                        <span>CPA (cost per acquisition)</span> total costs/ # of leads
                    </p>
                    <p>
                        <span>CPC (cost per click)</span> total costs/ # of clicks
                    </p>
                    {/* <p>
                        <span>ARPU (average revenue per user)</span> total revenue / # of customers
                    </p> */}
                </Cheatsheet>
            </ModalWrapper>

            <SmallIcon
                onMouseEnter={() => setVisibility(true)}
                onMouseLeave={() => setVisibility(false)}
            >
                ?
            </SmallIcon>
        </>
    );
};

export default KPICheatsheet;
