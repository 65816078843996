import React, { FormEvent, useState } from 'react';
import ModalWrapper from '../modals/ModalWrapper';
import {
    ButtonPrimary,
    FeedbackModal,
    Heading4,
    HelpIcon,
    InputWrapper,
    TextArea
} from '../StyledComponents';
import { ChatbubbleOutline } from 'react-ionicons';

export type FeedbackProps = {};

const webhookUrl =
    'https://hooks.slack.com/services/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';
const productionChannel = '#takkt-feedback';
const testChannel = '#takkt-feedback-test';

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const Feedback = (props: FeedbackProps) => {
    const [visible, setVisibility] = useState<boolean>(false);
    const [text, setText] = useState('');

    const submit = (e: FormEvent) => {
        e.preventDefault();

        const userEmail = parseJwt(localStorage.getItem('token')!).email || '[invalid token]';

        const body = {
            channel: process.env.NODE_ENV === 'production' ? productionChannel : testChannel,
            attachments: [
                {
                    fallback: `Feedback from ${userEmail}: ${text}`,
                    color: '#fffffe',
                    fields: [
                        {
                            title: userEmail,
                            value: text,
                            short: false
                        }
                    ]
                }
            ]
        };

        fetch(webhookUrl, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(() => {
            setText('');
            setVisibility(false);
        });
    };

    return (
        <>
            <ModalWrapper onClose={() => setVisibility(false)}>
                <FeedbackModal visible={visible}>
                    <Heading4>Feedback</Heading4>
                    <form onSubmit={submit}>
                        <InputWrapper>
                            <TextArea
                                darker
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                placeholder='Tell us about a bug or request a new feature...'
                            />
                        </InputWrapper>
                        <ButtonPrimary small>Send</ButtonPrimary>
                    </form>
                </FeedbackModal>
            </ModalWrapper>

            <HelpIcon onClick={() => setVisibility(!visible)}>
                <ChatbubbleOutline
                    color={'white'}
                    height='25px'
                    width='25px'
                    style={{ marginBottom: '-5px' }}
                />
            </HelpIcon>
        </>
    );
};

export default Feedback;
