import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Redirect, useParams } from 'react-router-dom';

import { Container, FlexWrapper, Heading2, Header } from '../StyledComponents';

import ReportGrid from '../layout/ReportGrid';
import Loading from '../elements/Loading';

const Report: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const GET_REPORT = gql`
        query GetReport {
            report(publicId: "${id}") {
                publicId
                name
                description
                layouts
                dateFilter {
                    startDate
                    endDate
                }
                items {
                    itemType
                    publicId
                    renderSelection
                    displayMode
                    inReport
                }
                resolvedItems {
                    ... on DataItem {
                        publicId
                        name
                        values {
                            value
                            timestamp
                        }
                        target {
                            value
                            dueDate
                        }
                        unit
                    }

                    ... on DataWidget {
                        publicId
                        name
                        description
                        operation
                        resolvedDataSources {
                            publicId
                            name
                            values {
                                value
                                timestamp
                            }
                            target {
                                value
                                dueDate
                            }
                            unit
                            target {
                                value
                                dueDate
                            }
                        }
                    }
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(GET_REPORT, { fetchPolicy: 'no-cache' });

    if (loading) {
        return <Loading />;
    } else if (error) {
        console.log(error);
        return <Loading />;
    } else if (data && data.report === null) {
        return <Redirect to='/' />;
    }

    const { name, description, layouts, items, resolvedItems, dateFilter } = data.report;

    const { startDate, endDate } = dateFilter;
    let dateRange = '';

    if (startDate !== null && endDate !== null) {
        const startString = new Date(startDate * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        const endString = new Date(endDate * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        dateRange = `${startString} - ${endString}`;
    }

    return (
        <Container>
            <Header>
                <FlexWrapper col>
                    <Heading2>Report for: {name}</Heading2>
                    <p>{description}</p>
                </FlexWrapper>
                <p>{dateRange}</p>
            </Header>

            <ReportGrid
                items={items}
                resolvedItems={resolvedItems}
                layoutsString={layouts}
                dateFilter={dateFilter}
            />
        </Container>
    );
};

export default Report;
