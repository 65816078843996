import { DataItem } from '../graphql/generated/graphql';
import Spline from './Spline';

function calcWidgetValues(dataItems: DataItem[], operation: string) {
    if (dataItems.filter((d) => d.values.length > 0).length === 0) return [];

    let timestamps: number[] = [];

    dataItems.forEach((dataItem) => {
        let ts = dataItem.values.map((v) => v.timestamp) as number[];
        timestamps.push(...ts);
    });

    // @ts-ignore
    timestamps = [...new Set(timestamps)].sort(); // timestep union

    // interpolate every dataitem to timestep union
    const dataItemValues = dataItems.map((di) => {
        // get values array
        const y = di.values.map((v) => v.value!);
        const x = di.values.map((v) => v.timestamp);
        const spline = new Spline(x, y);

        return timestamps.map((t) => spline.at(t));
    });

    const calc_values = timestamps.map((value, index) => {
        // operation = '$0/$1'
        const replacer = (match: string, ...args: any[]): string => {
            const i = +match.slice(1);
            const value = dataItemValues[i][index];
            return String(value);
        };
        const evalString = operation.replace(/\$\d+/g, replacer);

        // eslint-disable-next-line
        return +eval(evalString) as number;
    });

    return calc_values.map((v, index) => ({
        value: v === Infinity || isNaN(v) ? 0 : parseFloat(v.toFixed(2)),
        timestamp: timestamps[index]
    }));
}

export default calcWidgetValues;
