import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

 html {
   font-family: ${(props) => props.theme.fontFamily};
   font-size: 10px;
  }
 
 body {
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.middleGrey};
    font-size: 1.6rem;
    padding-top: 9rem;
    padding-bottom: 5rem;
  }

  #root {
    height: 100%;
  }
  
  img {
    max-width: 100%;
  }

  a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }

  input[type=date] {
    font-family: ${(props) => props.theme.fontFamily};
  }

  .react-grid-item.react-grid-placeholder {
    background: ${(props) => props.theme.colors.white};
    opacity: 0.3;
    transition-duration: 100ms;
    border-radius: ${(props) => props.theme.borderRadius2};
}

.react-grid-item > .react-resizable-handle {
    background: none;
}

.react-grid-item > .react-resizable-handle::after {
    border-color: ${(props) => props.theme.colors.lightGrey};
    bottom: 12px;
    right: 12px;
}

@media screen and (max-width: 600px) {
  body {
    padding-top: 8rem;
  }
}
`;

export default GlobalStyle;
