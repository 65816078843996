import React, { useEffect } from 'react';
import Loading from '../elements/Loading';

const BadLogin = () => {
    useEffect(() => {
        window.location.replace('https://www.takkt.io/auth/');
    }, []);

    return <Loading />;
};

export default BadLogin;
