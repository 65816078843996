import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Loading from '../elements/Loading';

const TokenHandler: React.FC = () => {
    const params = useParams();

    // @ts-ignore
    const { token, base64redirect } = params;
    const history = useHistory();

    localStorage.setItem('token', token);

    useEffect(() => {
        localStorage.setItem('token', token);
        if (!base64redirect) {
            history.push(`/dashboard`);
        } else {
            const redirect = Buffer.from(base64redirect, 'base64').toString('utf-8');

            history.push(`${redirect}`, {
                notification: {
                    text: 'You successfully logged in.',
                    type: 'success'
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return <Loading />;
};

export default TokenHandler;
