import React, { useEffect, useRef } from 'react';

const ModalWrapper: React.FC<{ onClose: any }> = ({ onClose, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clickListener = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) onClose();
        };

        const escapeListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') onClose();
        };

        document.addEventListener('click', clickListener, true);
        document.addEventListener('keyup', escapeListener, true);

        return () => {
            document.removeEventListener('click', clickListener, true);
            document.removeEventListener('keyup', escapeListener, true);
        };
    }, [ref, onClose]);

    return <div ref={ref}>{children}</div>;
};

export default ModalWrapper;
