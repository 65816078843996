import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DefaultTheme, withTheme } from 'styled-components';
import { CloudUpload, Keypad } from 'react-ionicons';

import ModalWrapper from './ModalWrapper';

import {
    FlexWrapper,
    ButtonPrimary,
    Heading3,
    Modal,
    Input,
    CloseIcon,
    ModalContainer,
    InputWrapper,
    ModalError,
    CSVInputWrapper,
    Heading4
} from '../StyledComponents';

import { CREATE_METRIC, ADD_ITEM_TO_BOARD } from '../../graphql/queries';
import { ItemType } from '../../graphql/generated/graphql';
import { ItemRefNamed } from '../pages/Board';
import { CSVReader } from 'react-papaparse';

interface Props {
    closeModal: () => void;
    boardId: string;
    items: ItemRefNamed[];
    theme: DefaultTheme;
}

const CreateMetric: React.FC<Props> = (props: Props) => {
    const { closeModal, boardId, theme } = props;

    // Common inputs
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [showCSV, setShowCSV] = useState(false);
    const [uploadedDataItems, setUploadedDataItems] = useState([]);

    // Simple
    const [unit, setUnit] = useState('');

    const [createItem] = useMutation(CREATE_METRIC);
    const [updateBoard] = useMutation(ADD_ITEM_TO_BOARD);

    const createMetric = () => {
        if (name.trim() !== '') {
            createItem({
                variables: {
                    name,
                    unit,
                    description: ''
                }
            })
                .then((res) => {
                    updateBoard({
                        variables: {
                            publicId: res.data.createItem.publicId,
                            boardId,
                            itemType: ItemType.Dataitem
                        }
                    }).then(() => {
                        setName('');
                        setUnit('');
                        closeModal();
                    });
                })
                .catch((e) => console.log(e));
        } else {
            setError('Please fill out all fields before submitting.');
        }
    };

    const uploadMetric = (index: number) => {
        const dataItem: any = uploadedDataItems[index];
        createItem({
            variables: {
                name: dataItem.name,
                unit: dataItem.unit,
                description: '',
                values: dataItem.values
            }
        }).then((res) => {
            updateBoard({
                variables: {
                    publicId: res.data.createItem.publicId,
                    boardId,
                    itemType: ItemType.Dataitem
                }
            }).then(() => {
                const slicedDataItems = [...uploadedDataItems];
                slicedDataItems.splice(index, 1);
                setUploadedDataItems(slicedDataItems);
            });
        });
    };

    const handleOnDrop = (data: any) => {
        console.log(data);
        let dataItems: any = [];
        data.forEach((row: any, i: number) => {
            if (i === 0) {
                // first row == header row, populate the dataItems object
                row.data.forEach((field: string, j: number) => {
                    // again skip first field (should be the date column) or if field is empty
                    if (j !== 0 && field !== '') {
                        dataItems.push({
                            name: field,
                            unit: '',
                            description: '',
                            values: []
                        });
                    }
                });
            } else {
                // every other row contains now our data
                let timestamp: number;
                row.data.forEach((value: string, j: number) => {
                    if (j === 0) {
                        timestamp = Date.parse(value) / 1000;
                    } else {
                        try {
                            value = value.trim();
                            const match = value.match(/\d+[,.]?\d*/);
                            let float;
                            if (match) {
                                float = parseFloat(match[0].replace('.', '').replace(',', '.'));
                            } else {
                                float = NaN;
                            }
                            if (!isNaN(timestamp) && !isNaN(float)) {
                                // only match numbers
                                dataItems[j - 1].values.push({ timestamp, value: float });

                                if (dataItems[j - 1].unit === '' && value.match(/[^\d.,]/)) {
                                    dataItems[j - 1].unit = value.match(/[^\d.,]/)![0];
                                }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                });
            }
        });
        setUploadedDataItems(dataItems);
    };

    const handleOnError = (err: any, file: any, inputElement: any, reason: any) => {
        console.log(err);
    };

    return (
        <ModalWrapper onClose={closeModal}>
            <ModalContainer
                onSubmit={(e) => {
                    e.preventDefault();
                    createMetric();
                }}
            >
                <Modal>
                    <CloseIcon onClick={closeModal} />

                    <Heading3>Add new metric</Heading3>

                    <FlexWrapper col>
                        {showCSV ? (
                            <CSVInputWrapper>
                                <CSVReader
                                    onDrop={handleOnDrop}
                                    onError={handleOnError}
                                    addRemoveButton
                                >
                                    <span>Drop CSV file here or click to upload.</span>
                                </CSVReader>
                                {uploadedDataItems.map((dataItem: any, index: number) => (
                                    <FlexWrapper key={dataItem.name}>
                                        <FlexWrapper col>
                                            <Heading4>
                                                {dataItem.name}
                                                {dataItem.unit !== '' && ` (${dataItem.unit})`}
                                            </Heading4>
                                            <span>{dataItem.values.length} items</span>
                                        </FlexWrapper>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => uploadMetric(index)}
                                        >
                                            Add
                                        </p>
                                    </FlexWrapper>
                                ))}
                            </CSVInputWrapper>
                        ) : (
                            <>
                                <InputWrapper>
                                    <label>Title</label>
                                    <Input
                                        placeholder='e.g. sales, leads, orders'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Unit</label>
                                    <Input
                                        placeholder='e.g. €, % (or leave empty for quantities)'
                                        value={unit}
                                        onChange={(e) => setUnit(e.target.value)}
                                    />
                                </InputWrapper>
                            </>
                        )}

                        {error.length > 0 && <ModalError>{error}</ModalError>}

                        <FlexWrapper>
                            <ButtonPrimary>Create</ButtonPrimary>
                            <p style={{ cursor: 'pointer' }} onClick={() => setShowCSV(!showCSV)}>
                                {showCSV ? (
                                    <span>
                                        <Keypad
                                            color={theme.colors.middleGrey}
                                            style={{ margin: '0 4px -4px 0' }}
                                            height='1.1em'
                                            width='1.1em'
                                        />
                                        Add values manually
                                    </span>
                                ) : (
                                    <span>
                                        <CloudUpload
                                            color={theme.colors.middleGrey}
                                            style={{ margin: '0 4px -4px 0' }}
                                            height='1.1em'
                                            width='1.1em'
                                        />
                                        Import from CSV file
                                    </span>
                                )}
                            </p>
                        </FlexWrapper>
                    </FlexWrapper>
                </Modal>
            </ModalContainer>
        </ModalWrapper>
    );
};

export default withTheme(CreateMetric);
