import React, { Fragment, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import GlobalStyle from '../style/globalStyles';
import { lightTheme, darkTheme } from '../style/theme';
import { useDarkMode } from './DarkMode';

import Navbar from './elements/Navbar';
import Board from './pages/Board';
import Report from './pages/Report';
import Overview from './pages/Overview';
import TokenHandler from './auth/TokenHandler';
import BadLogin from './auth/BadLogin';
import ProtectedRoute from './auth/ProtectedRoute';
import Confirmation from './modals/Confirmation';

const App = () => {
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warning, setWarning] = useState<{
        title: string;
        message: string;
        button: string;
        error: boolean;
        action: () => void;
    }>({
        title: 'Warning',
        message: 'Are you sure?',
        button: 'Confirm',
        error: false,
        action: () => ''
    });

    const { theme, toggleTheme } = useDarkMode();

    return (
        <Fragment>
            <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                <GlobalStyle />

                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Takkt - Boards</title>
                    <meta name='description' content='All your KPIs in one place.' />
                </Helmet>

                <Router>
                    <Navbar toggleTheme={toggleTheme} darkTheme={theme === 'dark'} />

                    <Switch>
                        <ProtectedRoute noToken path={'/blackmagic/:token/:base64redirect'}>
                            <TokenHandler />
                        </ProtectedRoute>

                        <ProtectedRoute noToken path={'/blackmagic/:token'}>
                            <TokenHandler />
                        </ProtectedRoute>

                        <Route path={'/error'}>
                            <BadLogin />
                        </Route>

                        <Route path='/report/:id'>
                            <Report />
                        </Route>

                        <ProtectedRoute path='/board/:id'>
                            <Board
                                showWarning={(warning: any) => {
                                    setWarning(warning);
                                    setShowWarning(true);
                                }}
                            />
                        </ProtectedRoute>

                        <ProtectedRoute path='/'>
                            <Overview
                                showWarning={(warning: any) => {
                                    setWarning(warning);
                                    setShowWarning(true);
                                }}
                            />
                        </ProtectedRoute>
                    </Switch>

                    {showWarning && (
                        <Confirmation
                            title={warning.title}
                            message={warning.message}
                            button={warning.button}
                            error={warning.error}
                            closeModal={() => setShowWarning(false)}
                            action={warning.action}
                        />
                    )}
                </Router>
            </ThemeProvider>
        </Fragment>
    );
};

export default App;
