import React, { useState } from 'react';

import ModalWrapper from './ModalWrapper';

import {
    FlexWrapper,
    ButtonPrimary,
    Heading3,
    Modal,
    Input,
    CloseIcon,
    ModalContainer,
    ModalError
} from '../StyledComponents';

interface Props {
    title: string;
    buttonText: string;
    closeModal: () => void;
    action: (emails: string[], callback: () => void) => void;
}

const EmailModal: React.FC<Props> = (props: Props) => {
    const { title, buttonText, closeModal, action } = props;

    const [emails, setEmails] = useState('');
    const [error, setError] = useState('');

    const submit = (e: any) => {
        e.preventDefault();

        function validateEmail(email: string) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        const separated = emails
            .split(',')
            .map((e) => e.trim())
            .filter((e) => e.length > 0);

        let emailsAreValid = emails.length > 0;
        separated.forEach((e) => {
            if (!validateEmail(e)) emailsAreValid = false;
        });

        if (emailsAreValid) {
            action(separated, () => {
                setEmails('');
                closeModal();
            });
        } else {
            setError('Make sure all email addresses are valid.');
        }
    };

    return (
        <ModalWrapper onClose={closeModal}>
            <ModalContainer onSubmit={submit}>
                <Modal>
                    <CloseIcon onClick={() => closeModal()} />
                    <Heading3>{title}</Heading3>

                    <FlexWrapper col>
                        <Input
                            placeholder='Emails, separated by commas'
                            value={emails}
                            onChange={(e) => setEmails(e.target.value)}
                        />

                        {error.length > 0 && <ModalError>{error}</ModalError>}

                        <ButtonPrimary>{buttonText}</ButtonPrimary>
                    </FlexWrapper>
                </Modal>
            </ModalContainer>
        </ModalWrapper>
    );
};

export default EmailModal;
