import { gql } from '@apollo/client';

/**
 * Overview
 */
const GET_BOARDS = gql`
    query GetBoards {
        user {
            email
            nickname
            boards {
                publicId
                participants {
                    nickname
                }
                name
                description
                dateUpdated
                items {
                    publicId
                }
            }
        }
    }
`;

/**
 * List
 */
const DELETE_BOARD = gql`
    mutation DeleteBoard($publicId: String!) {
        deleteBoard(publicId: $publicId)
    }
`;

const UPDATE_BOARD_ORDER = gql`
    mutation UpdateBoardOrder($from: Int!, $to: Int!) {
        updateUser(userUpdate: { boardOrder: { fromIndex: $from, toIndex: $to } }) {
            email
        }
    }
`;

/**
 * CRUD Board
 */
const CREATE_BOARD = gql`
    mutation CreateBoard($name: String!, $description: String!) {
        createBoard(objectInput: { name: $name, description: $description }) {
            publicId
        }
    }
`;

const UPDATE_BOARD_INFO = gql`
    mutation UpdateBoardInfo(
        $publicId: ID!
        $name: String!
        $description: String!
        $dateFilter: DataFilterInput
    ) {
        updateBoard(
            objectUpdate: {
                publicId: $publicId
                name: $name
                description: $description
                dateFilter: $dateFilter
            }
        ) {
            publicId
        }
    }
`;

const ADD_ITEM_TO_BOARD = gql`
    mutation UpdateBoard($publicId: String!, $boardId: ID!, $itemType: ItemType!) {
        updateBoard(
            objectUpdate: {
                publicId: $boardId
                itemRefsToAdd: [
                    {
                        publicId: $publicId
                        itemType: $itemType
                        renderSelection: SIMPLE
                        inReport: false
                        displayMode: SUM
                    }
                ]
            }
        ) {
            publicId
        }
    }
`;

const REAL_TIME_BOARD = gql`
    subscription RealTimeBoard($publicId: ID!) {
        itemUpdated(publicIds: [$publicId]) {
            ... on Board {
                publicId
                name
                description
                layouts
                items {
                    publicId
                    renderSelection
                    inReport
                    displayMode
                    itemType
                }
            }
        }
    }
`;

const UPDATE_LAYOUT = gql`
    mutation UpdateLayout($boardId: ID!, $layouts: String!) {
        updateBoard(objectUpdate: { publicId: $boardId, layouts: $layouts }) {
            publicId
        }
    }
`;

const UPDATE_METRIC_VIEW = gql`
    mutation UpdateMetricView(
        $boardId: ID!
        $publicId: String!
        $renderSelection: RenderOption!
        $itemType: ItemType!
        $inReport: Boolean!
        $displayMode: DisplayMode!
    ) {
        updateBoard(
            objectUpdate: {
                publicId: $boardId
                itemRefsToUpdate: [
                    {
                        publicId: $publicId
                        itemType: $itemType
                        renderSelection: $renderSelection
                        inReport: $inReport
                        displayMode: $displayMode
                    }
                ]
            }
        ) {
            publicId
        }
    }
`;

const SEND_REPORT = gql`
    mutation SendReport($boardId: String!, $emails: [String!]!) {
        sendReport(publicId: $boardId, invitees: $emails)
    }
`;

const INVITE_OTHERS = gql`
    mutation InviteCollaborators($boardId: ID!, $emails: [String!]!) {
        updateBoard(objectUpdate: { publicId: $boardId, participantEmailsToAdd: $emails }) {
            publicId
        }
    }
`;

const GET_BOARD = gql`
    query GetBoard($publicId: String) {
        board(publicId: $publicId) {
            publicId
            name
            description
            layouts
            dateFilter {
                startDate
                endDate
            }
            items {
                itemType
                publicId
                renderSelection
                inReport
                displayMode
            }
        }
    }
`;

const ITEM_NAMES = gql`
    query ItemNames($itemIdentifiers: [ItemIdentifier!]!) {
        items(items: $itemIdentifiers) {
            ... on DataItem {
                publicId
                name
            }
            ... on DataWidget {
                publicId
                name
            }
        }
    }
`;

/**
 * CRUD Metric
 */
const CREATE_METRIC = gql`
    mutation CreateMetric(
        $name: String!
        $unit: String!
        $description: String!
        $values: [ValueInput!]
    ) {
        createItem(
            objectInput: {
                itemType: DATAITEM
                objectInput: {
                    dataItemInput: {
                        name: $name
                        description: $description
                        unit: $unit
                        values: $values
                    }
                }
            }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const GET_DATAITEMS = gql`
    query GetDataItems($publicIds: [String!]!) {
        dataItems(publicIds: $publicIds) {
            publicId
            name
            values {
                value
                timestamp
            }
        }
    }
`;

const GET_METRIC = gql`
    query GetDataItem($publicId: String!) {
        item(publicId: $publicId, itemType: DATAITEM) {
            ... on DataItem {
                publicId
                name
                description
                unit
                values {
                    value
                    timestamp
                }
                target {
                    value
                    dueDate
                    tolerance
                }
                renderOptions
                widgets
                dateCreated
                dateUpdated
            }
        }
    }
`;

const GET_WIDGET = gql`
    query GetDataWidget($publicId: String!) {
        item(publicId: $publicId, itemType: DATAWIDGET) {
            ... on DataWidget {
                publicId
                name
                description
                operation
                dataSources {
                    publicId
                }
                target {
                    value
                    dueDate
                }
            }
        }
    }
`;

const DELETE_ITEM = gql`
    mutation DeleteDataItem($publicId: ID!, $itemType: ItemType!) {
        deleteItem(publicId: $publicId, itemType: $itemType)
    }
`;

const REMOVE_METRIC_FROM_BOARD = gql`
    mutation RemoveItemFromBoard($boardId: ID!, $publicId: String!) {
        updateBoard(objectUpdate: { publicId: $boardId, itemPublicIdsToRemove: [$publicId] }) {
            publicId
        }
    }
`;

const ADD_VALUE = gql`
    mutation AddValue($publicId: ID!, $values: [ValueInput!]!) {
        updateItem(
            itemType: DATAITEM
            objectUpdate: { dataItemUpdate: { publicId: $publicId, values: $values } }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const DELETE_TARGET_ITEM = gql`
    mutation DeleteTarget($publicId: ID!) {
        updateItem(
            itemType: DATAITEM
            objectUpdate: { dataItemUpdate: { publicId: $publicId, resetTarget: true } }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const DELETE_TARGET_WIDGET = gql`
    mutation DeleteTarget($publicId: ID!) {
        updateItem(
            itemType: DATAWIDGET
            objectUpdate: { dataWidgetUpdate: { publicId: $publicId, resetTarget: true } }
        ) {
            ... on DataWidget {
                publicId
            }
        }
    }
`;

const EDIT_TARGET_ITEM = gql`
    mutation EditTarget($publicId: ID!, $value: Float!, $dueDate: Date!) {
        updateItem(
            itemType: DATAITEM
            objectUpdate: {
                dataItemUpdate: {
                    publicId: $publicId
                    target: { value: $value, dueDate: $dueDate }
                }
            }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const EDIT_TARGET_WIDGET = gql`
    mutation EditTarget($publicId: ID!, $value: Float!, $dueDate: Date!) {
        updateItem(
            itemType: DATAWIDGET
            objectUpdate: {
                dataWidgetUpdate: {
                    publicId: $publicId
                    target: { value: $value, dueDate: $dueDate }
                }
            }
        ) {
            ... on DataWidget {
                publicId
            }
        }
    }
`;

const EDIT_UNIT_ITEM = gql`
    mutation EditUnit($publicId: ID!, $unit: String!) {
        updateItem(
            itemType: DATAITEM
            objectUpdate: { dataItemUpdate: { publicId: $publicId, unit: $unit } }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const CHANGE_ITEM_TITLE = gql`
    mutation ChangeMetricTitle($publicId: ID!, $newTitle: String!) {
        updateItem(
            itemType: DATAITEM
            objectUpdate: { dataItemUpdate: { publicId: $publicId, name: $newTitle } }
        ) {
            ... on DataItem {
                publicId
            }
        }
    }
`;

const CHANGE_WIDGET_TITLE = gql`
    mutation ChangeWidgetTitle($publicId: ID!, $newTitle: String!) {
        updateItem(
            itemType: DATAWIDGET
            objectUpdate: { dataWidgetUpdate: { publicId: $publicId, name: $newTitle } }
        ) {
            ... on DataWidget {
                publicId
            }
        }
    }
`;

const CREATE_DATA_WIDGET = gql`
    mutation CreateDataWidget(
        $publicId1: ID!
        $type1: DataSourceType!
        $publicId2: ID!
        $type2: DataSourceType!
        $operation: String!
        $name: String!
    ) {
        createItem(
            objectInput: {
                itemType: DATAWIDGET
                objectInput: {
                    dataWidgetInput: {
                        name: $name
                        dataSources: [
                            { publicId: $publicId1, dataSourceType: $type1 }
                            { publicId: $publicId2, dataSourceType: $type2 }
                        ]
                        operation: $operation
                    }
                }
            }
        ) {
            ... on DataWidget {
                publicId
            }
        }
    }
`;

const REAL_TIME_ITEM = gql`
    subscription RealTimeItem($publicIds: [ID!]!) {
        itemUpdated(publicIds: $publicIds) {
            ... on DataItem {
                publicId
                name
                description
                unit
                values {
                    value
                    timestamp
                }
                target {
                    value
                    dueDate
                    tolerance
                }
                renderOptions
                widgets
                dateCreated
                dateUpdated
            }
        }
    }
`;

const REAL_TIME_WIDGET = gql`
    subscription RealTimeWidget($publicId: ID!) {
        itemUpdated(publicIds: [$publicId]) {
            ... on DataWidget {
                publicId
                name
                description
                operation
                dataSources {
                    publicId
                }
                target {
                    value
                    dueDate
                }
            }
        }
    }
`;

export {
    GET_BOARDS,
    DELETE_BOARD,
    UPDATE_BOARD_ORDER,
    ADD_ITEM_TO_BOARD,
    CREATE_BOARD,
    UPDATE_BOARD_INFO,
    CREATE_METRIC,
    REAL_TIME_BOARD,
    GET_METRIC,
    CHANGE_ITEM_TITLE,
    CHANGE_WIDGET_TITLE,
    REMOVE_METRIC_FROM_BOARD,
    ADD_VALUE,
    EDIT_TARGET_ITEM,
    EDIT_TARGET_WIDGET,
    UPDATE_LAYOUT,
    UPDATE_METRIC_VIEW,
    DELETE_ITEM,
    GET_WIDGET,
    GET_DATAITEMS,
    CREATE_DATA_WIDGET,
    SEND_REPORT,
    INVITE_OTHERS,
    REAL_TIME_ITEM,
    REAL_TIME_WIDGET,
    GET_BOARD,
    ITEM_NAMES,
    DELETE_TARGET_WIDGET,
    DELETE_TARGET_ITEM,
    EDIT_UNIT_ITEM
};
