import React, { useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { DragSwitch } from 'react-dragswitch';
import { DefaultTheme, withTheme } from 'styled-components';
import { useQuery } from '@apollo/client';

import ModalWrapper from '../modals/ModalWrapper';

import { GET_BOARDS } from '../../graphql/queries';

import 'react-dragswitch/dist/index.css';

import {
    Container,
    FlexWrapper,
    Logo,
    NavLink,
    NavWrapper,
    Profile,
    NavbarMenu,
    ToggleWrapper,
    ReportNavMenu,
    DumbNavLink
} from '../StyledComponents';

const compose = (...rest: any[]) => (x: any) => rest.reduceRight((y, f) => f(y), x);

function logout() {
    localStorage.removeItem('token');
    window.location.replace('https://www.takkt.io/auth/');
}

interface Props {
    theme: DefaultTheme;
    toggleTheme: () => void;
    darkTheme: boolean;
    history: any;
}

const Navbar: React.FC<Props> = ({ theme, toggleTheme, darkTheme, history }) => {
    const [showLogout, setShowLogout] = useState(false);

    const { pathname } = useLocation();

    // Trivially determine the page the user is on
    let page;

    switch (pathname.charAt(1)) {
        case 'r':
            page = 'report';
            break;
        case 'b':
            page = 'board';
            break;
        default:
            page = 'overview';
            break;
    }

    const authenticated = localStorage.getItem('token') !== null;

    // Only fetch user if authenticated (if ls token exists but is bad, it'll redirect to login)
    const { loading, error, data } = useQuery(GET_BOARDS, {
        skip: !authenticated
    });

    let render;

    if (authenticated) {
        if (loading) {
            render = 'Loading...';
        } else if (error) {
            render = '';
        } else {
            const {
                user: { nickname }
            } = data;

            render = (
                <FlexWrapper auto>
                    <>
                        {page === 'board' && <NavLink to='/'>Boards overview</NavLink>}

                        {page === 'report' && (
                            <DumbNavLink onClick={() => history.goBack()}>
                                Go back to board
                            </DumbNavLink>
                        )}

                        <Profile onClick={() => setShowLogout(!showLogout)}>
                            {nickname.charAt(0).toUpperCase()}
                        </Profile>

                        <ModalWrapper onClose={() => setShowLogout(false)}>
                            <NavbarMenu show={showLogout}>
                                <p>
                                    Signed in as: <b>{nickname}</b>
                                </p>

                                <ToggleWrapper>
                                    <DragSwitch
                                        checked={darkTheme}
                                        onChange={toggleTheme}
                                        onColor={theme.colors.primary}
                                        offColor={theme.colors.middleGrey}
                                        focusShadow='rgba(50,50,50,0.3) 0px 0px 2px 3px'
                                    />
                                    <span>{darkTheme ? 'Dark Theme' : 'Light Theme'}</span>
                                </ToggleWrapper>

                                <p onClick={logout}>Logout</p>
                            </NavbarMenu>
                        </ModalWrapper>
                    </>
                </FlexWrapper>
            );
        }
    } else {
        render = (
            <FlexWrapper auto>
                <ReportNavMenu>
                    <ToggleWrapper>
                        <DragSwitch
                            checked={darkTheme}
                            onChange={toggleTheme}
                            onColor={theme.colors.primary}
                            offColor={theme.colors.middleGrey}
                            focusShadow='rgba(50,50,50,0.3) 0px 0px 2px 3px'
                        />
                        <span>{darkTheme ? 'Dark Theme' : 'Light Theme'}</span>
                    </ToggleWrapper>
                </ReportNavMenu>
            </FlexWrapper>
        );
    }

    return (
        <NavWrapper>
            <Container>
                <FlexWrapper>
                    <Logo>
                        <Link to='/'>
                            Takkt<span>.</span>
                        </Link>
                    </Logo>

                    {render}
                </FlexWrapper>
            </Container>
        </NavWrapper>
    );
};

export default compose(withTheme, withRouter)(Navbar);
