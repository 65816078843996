export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Date custom scalar type */
    Date: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type Subscription = {
    __typename?: 'Subscription';
    /** Meeting subscriptions */
    postAdded?: Maybe<MeetingEvent>;
    meetingUpdated?: Maybe<Meeting>;
    taskStateUpdate?: Maybe<MeetingEvent>;
    postUpdate?: Maybe<MeetingEvent>;
    postDeleted?: Maybe<Scalars['String']>;
    userTyping?: Maybe<Array<Maybe<User>>>;
    /** Board subscriptions */
    boardUpdated?: Maybe<Board>;
    itemUpdated?: Maybe<Item>;
};

export type SubscriptionPostAddedArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionMeetingUpdatedArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionTaskStateUpdateArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionPostUpdateArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionPostDeletedArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionUserTypingArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionBoardUpdatedArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type SubscriptionItemUpdatedArgs = {
    publicIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Query = {
    __typename?: 'Query';
    ping?: Maybe<Scalars['String']>;
    /** User queries */
    user?: Maybe<User>;
    item?: Maybe<Item>;
    items?: Maybe<Array<Maybe<Item>>>;
    board?: Maybe<Board>;
    report?: Maybe<Report>;
    /** Basics */
    dataItems?: Maybe<Array<Maybe<DataItem>>>;
    /** Meeting queries */
    meeting?: Maybe<Meeting>;
    getAgenda: Array<AgendaItem>;
    meetingConfigOptions: Array<MeetingConfigOption>;
    /** Series queries */
    series?: Maybe<Series>;
};

export type QueryItemArgs = {
    publicId: Scalars['String'];
    itemType?: Maybe<ItemType>;
};

export type QueryItemsArgs = {
    items: Array<ItemIdentifier>;
};

export type QueryBoardArgs = {
    publicId?: Maybe<Scalars['String']>;
};

export type QueryReportArgs = {
    publicId?: Maybe<Scalars['String']>;
};

export type QueryDataItemsArgs = {
    publicIds: Array<Scalars['String']>;
};

export type QueryMeetingArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type QueryGetAgendaArgs = {
    businessSubject: Scalars['String'];
    numberOfParticipants: Scalars['String'];
    durationInMinutes: Scalars['String'];
};

export type QuerySeriesArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Mutations on basic types */
    singleUpload: UploadedFileResponse;
    createItem?: Maybe<Item>;
    updateItem?: Maybe<Item>;
    deleteItem?: Maybe<Scalars['String']>;
    /** Boards */
    createBoard: Board;
    updateBoard: Board;
    deleteBoard?: Maybe<Scalars['String']>;
    sendReport?: Maybe<Scalars['String']>;
    sendInvite?: Maybe<Scalars['String']>;
    /**
     * User mutations
     * deleteUser(userId: String): String
     */
    updateUser?: Maybe<User>;
    addMeetingToUser?: Maybe<User>;
    archiveMeeting?: Maybe<User>;
    addSeriesToUser?: Maybe<User>;
    removeSeriesFromUser?: Maybe<User>;
    /** Meeting mutations */
    createMeeting?: Maybe<Meeting>;
    addParticipantToMeeting?: Maybe<Participant>;
    addEventToMeeting?: Maybe<Scalars['String']>;
    deleteEventFromMeeting?: Maybe<Scalars['String']>;
    closeMeeting?: Maybe<Scalars['String']>;
    startMeeting?: Maybe<Scalars['String']>;
    setTaskDone?: Maybe<Scalars['String']>;
    updateCurrentTopic?: Maybe<Scalars['String']>;
    updatePost?: Maybe<Scalars['String']>;
    deletePost?: Maybe<Scalars['String']>;
    updateOnlineStatus?: Maybe<Scalars['String']>;
    setTypingStatus?: Maybe<Scalars['String']>;
    updateMeetingSettings?: Maybe<Scalars['String']>;
    /** Series mutations */
    createSeries?: Maybe<Series>;
    updateSeries?: Maybe<Series>;
    createNext?: Maybe<Meeting>;
    addOrUpdateMetric?: Maybe<Scalars['String']>;
};

export type MutationSingleUploadArgs = {
    file: Scalars['Upload'];
    meetingPublicId: Scalars['ID'];
};

export type MutationCreateItemArgs = {
    objectInput: ItemInput;
};

export type MutationUpdateItemArgs = {
    itemType: ItemType;
    objectUpdate: ItemUpdate;
};

export type MutationDeleteItemArgs = {
    publicId: Scalars['ID'];
    itemType: ItemType;
};

export type MutationCreateBoardArgs = {
    objectInput: BoardInput;
};

export type MutationUpdateBoardArgs = {
    objectUpdate: BoardUpdate;
};

export type MutationDeleteBoardArgs = {
    publicId: Scalars['String'];
};

export type MutationSendReportArgs = {
    publicId: Scalars['String'];
    invitees: Array<Scalars['String']>;
};

export type MutationSendInviteArgs = {
    publicId: Scalars['String'];
    invitees: Array<Scalars['String']>;
};

export type MutationUpdateUserArgs = {
    userUpdate?: Maybe<UserUpdate>;
};

export type MutationAddMeetingToUserArgs = {
    meetingId?: Maybe<Scalars['ID']>;
};

export type MutationArchiveMeetingArgs = {
    meetingId?: Maybe<Scalars['ID']>;
};

export type MutationAddSeriesToUserArgs = {
    seriesId?: Maybe<Scalars['ID']>;
};

export type MutationRemoveSeriesFromUserArgs = {
    seriesId?: Maybe<Scalars['ID']>;
};

export type MutationCreateMeetingArgs = {
    meetingInput?: Maybe<MeetingInput>;
};

export type MutationAddParticipantToMeetingArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type MutationAddEventToMeetingArgs = {
    publicId?: Maybe<Scalars['ID']>;
    meetingEvent?: Maybe<MeetingEventInput>;
};

export type MutationDeleteEventFromMeetingArgs = {
    meetingId?: Maybe<Scalars['ID']>;
    meetingEventId?: Maybe<Scalars['String']>;
};

export type MutationCloseMeetingArgs = {
    publicId: Scalars['ID'];
};

export type MutationStartMeetingArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type MutationSetTaskDoneArgs = {
    publicId?: Maybe<Scalars['ID']>;
    hash?: Maybe<Scalars['String']>;
    done?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateCurrentTopicArgs = {
    publicId?: Maybe<Scalars['ID']>;
    direction?: Maybe<TopicUpdateDirection>;
};

export type MutationUpdatePostArgs = {
    publicId?: Maybe<Scalars['ID']>;
    post?: Maybe<MeetingEventInput>;
};

export type MutationDeletePostArgs = {
    publicId?: Maybe<Scalars['ID']>;
    hash?: Maybe<Scalars['String']>;
};

export type MutationUpdateOnlineStatusArgs = {
    publicId?: Maybe<Scalars['ID']>;
};

export type MutationSetTypingStatusArgs = {
    publicId?: Maybe<Scalars['ID']>;
    typingStatus?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateMeetingSettingsArgs = {
    publicId?: Maybe<Scalars['ID']>;
    meetingSettingsUpdate?: Maybe<MeetingSettingsUpdateInput>;
};

export type MutationCreateSeriesArgs = {
    seriesInput?: Maybe<SeriesInput>;
};

export type MutationUpdateSeriesArgs = {
    publicId: Scalars['ID'];
    update?: Maybe<SeriesUpdate>;
};

export type MutationCreateNextArgs = {
    publicId: Scalars['ID'];
};

export type MutationAddOrUpdateMetricArgs = {
    publicId: Scalars['ID'];
    metric?: Maybe<MetricInput>;
};

/** Random things */
export enum MagicLinkType {
    SignupAndRedirect = 'SIGNUP_AND_REDIRECT',
    LoginAndRedirect = 'LOGIN_AND_REDIRECT'
}

export type MagicLinkPayload = {
    __typename?: 'MagicLinkPayload';
    type?: Maybe<MagicLinkType>;
    email?: Maybe<Scalars['String']>;
    origin?: Maybe<Scalars['String']>;
    targetUrl?: Maybe<Scalars['String']>;
};

/** User */
export type User = {
    __typename?: 'User';
    _id?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
    email: Scalars['String'];
    emailConfirmed?: Maybe<Scalars['Boolean']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    nickname: Scalars['String'];
    meetings: Array<Meeting>;
    archivedMeetings?: Maybe<Array<Meeting>>;
    series: Array<Series>;
    boards: Array<Board>;
    archievedBoards: Array<Board>;
    userGroup?: Maybe<Scalars['String']>;
    dateCreated?: Maybe<Scalars['Date']>;
};

export type UserUpdate = {
    userId?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    nickname?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    boardOrder?: Maybe<BoardOrderInput>;
};

/** Board and aggregation types */
export type Board = {
    __typename?: 'Board';
    publicId: Scalars['ID'];
    owner: User;
    participants?: Maybe<Array<User>>;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    items: Array<ItemRef>;
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
    layouts?: Maybe<Scalars['String']>;
};

export type ItemRef = {
    __typename?: 'ItemRef';
    publicId: Scalars['String'];
    itemType: ItemType;
    renderSelection: RenderOption;
    inReport?: Maybe<Scalars['Boolean']>;
    displayMode?: Maybe<DisplayMode>;
};

export type ItemIdentifier = {
    publicId: Scalars['ID'];
    itemType: ItemType;
};

export type BoardInput = {
    name: Scalars['String'];
    description: Scalars['String'];
};

export type BoardUpdate = {
    publicId: Scalars['ID'];
    newOwnerEmail?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    itemRefsToAdd?: Maybe<Array<ItemRefInput>>;
    itemPublicIdsToRemove?: Maybe<Array<Scalars['String']>>;
    itemRefsToUpdate?: Maybe<Array<ItemRefInput>>;
    layouts?: Maybe<Scalars['String']>;
    participantEmailsToAdd?: Maybe<Array<Scalars['String']>>;
    participantEmailsToRemove?: Maybe<Array<Scalars['String']>>;
};

export type BoardOrderInput = {
    fromIndex?: Maybe<Scalars['Int']>;
    toIndex?: Maybe<Scalars['Int']>;
};

export type ItemRefInput = {
    publicId: Scalars['String'];
    itemType: ItemType;
    renderSelection: RenderOption;
    inReport: Scalars['Boolean'];
    displayMode: DisplayMode;
};

export type ItemRefUpdate = {
    publicId: Scalars['String'];
    itemType?: Maybe<ItemType>;
    renderSelection?: Maybe<RenderOption>;
    inReport?: Maybe<Scalars['Boolean']>;
    displayMode?: Maybe<DisplayMode>;
};

export enum ItemSizeOption {
    Onebyone = 'ONEBYONE',
    Onbytwo = 'ONBYTWO',
    Twobytwo = 'TWOBYTWO',
    Twobyfour = 'TWOBYFOUR'
}

export type Bucket = {
    __typename?: 'Bucket';
    publicId: Scalars['ID'];
    owner: User;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    items: Array<Item>;
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type Report = {
    __typename?: 'Report';
    publicId: Scalars['ID'];
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    items: Array<ItemRef>;
    resolvedItems?: Maybe<Array<Item>>;
    layouts?: Maybe<Scalars['String']>;
};

/** Item types */
export type Item = DataWidget | DataItem | Board;

export type ItemInput = {
    itemType: ItemType;
    objectInput: ItemPropertyInput;
};

export type ItemPropertyInput = {
    boardInput?: Maybe<BoardInput>;
    dataItemInput?: Maybe<DataItemInput>;
    dataWidgetInput?: Maybe<DataWidgetInput>;
};

export type ItemUpdate = {
    boardUpdate?: Maybe<BoardUpdate>;
    dataItemUpdate?: Maybe<DataItemUpdate>;
    dataWidgetUpdate?: Maybe<DataWidgetUpdate>;
};

export enum ItemType {
    Datawidget = 'DATAWIDGET',
    Dataitem = 'DATAITEM',
    Task = 'TASK',
    Note = 'NOTE',
    File = 'FILE',
    Bucket = 'BUCKET',
    Board = 'BOARD',
    Meeting = 'MEETING',
    Series = 'SERIES'
}

/** DataWidget definition */
export type DataWidget = {
    __typename?: 'DataWidget';
    _id?: Maybe<Scalars['String']>;
    publicId: Scalars['ID'];
    owner: User;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    target?: Maybe<DataItemTarget>;
    dataSources: Array<DataSource>;
    resolvedDataSources?: Maybe<Array<DataItem>>;
    operation: Scalars['String'];
    renderOptions: Array<RenderOption>;
    sizeOptions: Array<ItemSizeOption>;
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type DataSource = {
    __typename?: 'DataSource';
    publicId: Scalars['ID'];
    dataSourceType?: Maybe<DataSourceType>;
};

export type DataWidgetInput = {
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    dataSources: Array<DataSourceInput>;
    operation: Scalars['String'];
    target?: Maybe<DataItemTargetInput>;
};

export type DataSourceInput = {
    publicId: Scalars['ID'];
    dataSourceType?: Maybe<DataSourceType>;
};

export enum DataSourceType {
    Datawidget = 'DATAWIDGET',
    Dataitem = 'DATAITEM'
}

export type DataWidgetUpdate = {
    publicId: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    target?: Maybe<DataItemTargetInput>;
    operation?: Maybe<Scalars['String']>;
    dataSourceToAdd?: Maybe<DataSourceInput>;
    dataSourceToRemove?: Maybe<DataSourceInput>;
    renderOption?: Maybe<RenderOption>;
};

/** DataItem definition */
export type DataItem = {
    __typename?: 'DataItem';
    publicId: Scalars['ID'];
    owner: User;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    unit: Scalars['String'];
    values: Array<Value>;
    target?: Maybe<DataItemTarget>;
    renderOptions: Array<RenderOption>;
    sizeOptions: Array<ItemSizeOption>;
    widgets: Array<Scalars['String']>;
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type DataItemInput = {
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    values?: Maybe<Array<ValueInput>>;
    target?: Maybe<DataItemTargetInput>;
};

export type DataItemUpdate = {
    publicId: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    values?: Maybe<Array<ValueInput>>;
    target?: Maybe<DataItemTargetInput>;
};

export type Value = {
    __typename?: 'Value';
    value?: Maybe<Scalars['Float']>;
    timestamp?: Maybe<Scalars['Date']>;
};

export type ValueInput = {
    value: Scalars['Float'];
    timestamp: Scalars['Date'];
};

export type DataItemTarget = {
    __typename?: 'DataItemTarget';
    value?: Maybe<Scalars['Float']>;
    dueDate?: Maybe<Scalars['Date']>;
    tolerance?: Maybe<Scalars['Int']>;
};

export type DataItemTargetInput = {
    value: Scalars['Float'];
    dueDate?: Maybe<Scalars['Date']>;
    tolerance?: Maybe<Scalars['Int']>;
};

export enum RenderOption {
    Simple = 'SIMPLE',
    Linegraph = 'LINEGRAPH'
}

export enum DisplayMode {
    Single = 'SINGLE',
    Sum = 'SUM'
}

export type Note = {
    __typename?: 'Note';
    publicId: Scalars['ID'];
    author?: Maybe<User>;
    textBody: Scalars['String'];
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type Task = {
    __typename?: 'Task';
    publicId: Scalars['ID'];
    author?: Maybe<User>;
    description: Scalars['String'];
    subtasks: Array<Subtask>;
    responsible?: Maybe<User>;
    done: Scalars['Boolean'];
    dateDue?: Maybe<Scalars['Date']>;
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type Subtask = {
    __typename?: 'Subtask';
    description: Scalars['String'];
    done: Scalars['Boolean'];
};

export type File = {
    __typename?: 'File';
    publicId: Scalars['String'];
    fileId: Scalars['String'];
    owner: User;
    filename: Scalars['String'];
    mimetype: Scalars['String'];
    encoding: Scalars['String'];
    dateCreated?: Maybe<Scalars['Date']>;
    dateUpdated?: Maybe<Scalars['Date']>;
};

export type UploadedFileResponse = {
    __typename?: 'UploadedFileResponse';
    filename: Scalars['String'];
    mimetype: Scalars['String'];
    encoding: Scalars['String'];
    url: Scalars['String'];
};

/** Meeting and Series */
export type Meeting = {
    __typename?: 'Meeting';
    publicId: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    host?: Maybe<User>;
    invitees?: Maybe<Array<Maybe<User>>>;
    agenda: Array<AgendaItem>;
    meetingConfig: MeetingConfig;
    participants: Array<Participant>;
    meetingEvents: Array<Maybe<MeetingEvent>>;
    summary?: Maybe<MeetingSummary>;
    availableAvatars?: Maybe<Array<Maybe<Avatar>>>;
    currentTopic?: Maybe<Scalars['Int']>;
    topicHistory: Array<TopicHistoryItem>;
    created?: Maybe<Scalars['Date']>;
    started?: Maybe<Scalars['Date']>;
    closed?: Maybe<Scalars['Date']>;
    previous?: Maybe<Meeting>;
    next?: Maybe<Meeting>;
    series?: Maybe<Series>;
    start?: Maybe<Scalars['Date']>;
    files?: Maybe<Array<File>>;
    metrics: Array<Metric>;
};

export type MeetingInput = {
    title?: Maybe<Scalars['String']>;
    agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
    meetingConfig?: Maybe<MeetingConfigInput>;
    invitees?: Maybe<Array<Scalars['String']>>;
    previousPublicId?: Maybe<Scalars['String']>;
    seriesPublicId?: Maybe<Scalars['String']>;
    seriesObjectRef?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Date']>;
    metrics?: Maybe<Array<MetricInput>>;
};

export type MeetingSummary = {
    __typename?: 'MeetingSummary';
    notes?: Maybe<MeetingSummaryItem>;
    tasks?: Maybe<MeetingSummaryItem>;
    decisions?: Maybe<MeetingSummaryItem>;
};

export type MeetingSummaryItem = {
    __typename?: 'MeetingSummaryItem';
    identifier?: Maybe<MeetingEventType>;
    objects?: Maybe<Array<Maybe<MeetingEvent>>>;
};

export type MeetingConfig = {
    __typename?: 'MeetingConfig';
    businessSubject: BusinessSubject;
    numberOfParticipants: Scalars['String'];
    durationInMinutes: Scalars['String'];
};

export type MeetingConfigInput = {
    businessSubject: BusinessSubject;
    numberOfParticipants: Scalars['String'];
    durationInMinutes: Scalars['String'];
};

export type MeetingConfigOption = {
    __typename?: 'MeetingConfigOption';
    identifier: Scalars['String'];
    label: Scalars['String'];
    options: Array<MeetingConfigOptionItem>;
};

export type MeetingConfigOptionItem = {
    __typename?: 'MeetingConfigOptionItem';
    label: Scalars['String'];
    graphic?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    val: Scalars['String'];
};

export type MeetingSettingsUpdateInput = {
    title?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['Date']>;
    agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
    hostEmail?: Maybe<Scalars['String']>;
    addInviteesEmails?: Maybe<Array<Scalars['String']>>;
    removeInviteesEmails?: Maybe<Array<Scalars['String']>>;
    previousMeetingId?: Maybe<Scalars['ID']>;
    nextMeetingId?: Maybe<Scalars['ID']>;
    updateSeries?: Maybe<Scalars['Boolean']>;
};

export type MeetingEvent = {
    __typename?: 'MeetingEvent';
    type?: Maybe<MeetingEventType>;
    hash?: Maybe<Scalars['String']>;
    author: User;
    authorAvatar: Avatar;
    agendaItemId: Scalars['Int'];
    textBody?: Maybe<Scalars['String']>;
    responsible?: Maybe<User>;
    dueDate?: Maybe<Scalars['Date']>;
    done?: Maybe<Scalars['Boolean']>;
    timestamp_created?: Maybe<Scalars['Date']>;
};

export type MeetingEventInput = {
    type: MeetingEventType;
    hash: Scalars['String'];
    authorAvatar: AvatarInput;
    agendaItemId: Scalars['Int'];
    responsible?: Maybe<Scalars['String']>;
    textBody?: Maybe<Scalars['String']>;
    dueDate?: Maybe<Scalars['Date']>;
    done?: Maybe<Scalars['Boolean']>;
};

export enum MeetingEventType {
    Note = 'NOTE',
    Task = 'TASK',
    File = 'FILE',
    Decision = 'DECISION'
}

export type TopicHistoryItem = {
    __typename?: 'TopicHistoryItem';
    topic?: Maybe<Scalars['Int']>;
    duration?: Maybe<Scalars['Float']>;
    switchTimeStamp?: Maybe<Scalars['Float']>;
};

export type AgendaItem = {
    __typename?: 'AgendaItem';
    topic: Scalars['String'];
    durationInMinutes: Scalars['Int'];
};

export type AgendaItemInput = {
    topic?: Maybe<Scalars['String']>;
    durationInMinutes?: Maybe<Scalars['Int']>;
};

export type Series = {
    __typename?: 'Series';
    publicId: Scalars['String'];
    title: Scalars['String'];
    meetings: Array<Meeting>;
    host: User;
    participants: Array<User>;
    start?: Maybe<Scalars['Date']>;
    end?: Maybe<Scalars['Date']>;
    interval?: Maybe<SeriesInterval>;
    nextMeetingStart?: Maybe<Scalars['Date']>;
};

export type SeriesInput = {
    title: Scalars['String'];
    participantEmails: Array<Scalars['String']>;
    start: Scalars['Date'];
    end?: Maybe<Scalars['Date']>;
    interval?: Maybe<SeriesInterval>;
    agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
    meetingConfig?: Maybe<MeetingConfigInput>;
    previousPublicId?: Maybe<Scalars['String']>;
};

export type SeriesUpdate = {
    title?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['Date']>;
    interval?: Maybe<SeriesInterval>;
    addParticipants?: Maybe<Array<Scalars['String']>>;
    removeParticipants?: Maybe<Array<Scalars['String']>>;
    updateNextMeetingStart?: Maybe<Scalars['Boolean']>;
};

export enum SeriesInterval {
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
    MonthlyLast = 'MONTHLY_LAST',
    Yearly = 'YEARLY'
}

export type Participant = {
    __typename?: 'Participant';
    user: User;
    socketIds?: Maybe<Array<Maybe<Scalars['String']>>>;
    online?: Maybe<Scalars['Date']>;
    avatar: Avatar;
    isTyping?: Maybe<Scalars['Boolean']>;
};

export type Avatar = {
    __typename?: 'Avatar';
    avatarObject?: Maybe<Scalars['String']>;
    avatarType?: Maybe<AvatarType>;
};

export type AvatarInput = {
    avatarObject?: Maybe<Scalars['String']>;
    avatarType?: Maybe<AvatarType>;
};

export enum AvatarType {
    Emoji = 'EMOJI'
}

export enum MetricType {
    Number = 'NUMBER'
}

export type Metric = {
    __typename?: 'Metric';
    type?: Maybe<MetricType>;
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    suffix?: Maybe<Scalars['String']>;
    target?: Maybe<Scalars['Float']>;
    targetDueDate?: Maybe<Scalars['Date']>;
};

export type MetricInput = {
    type: MetricType;
    key: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    suffix?: Maybe<Scalars['String']>;
    target?: Maybe<Scalars['Float']>;
    targetDueDate?: Maybe<Scalars['Date']>;
};

export enum BusinessSubject {
    StatusUpdate = 'STATUS_UPDATE',
    DecisionMaking = 'DECISION_MAKING',
    ProblemSolving = 'PROBLEM_SOLVING',
    InformationSharing = 'INFORMATION_SHARING',
    Innovation = 'INNOVATION',
    Planning = 'PLANNING'
}

export enum TopicUpdateDirection {
    Prev = 'PREV',
    Next = 'NEXT'
}

export enum CacheControlScope {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Item: ['DataWidget', 'DataItem', 'Board']
    }
};
export default result;
