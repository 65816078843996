import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as log from 'loglevel';

const httpLink = new HttpLink({
    uri: String(process.env.REACT_APP_BACKEND_URL)
});

const wsLink = new WebSocketLink({
    uri: String(process.env.REACT_APP_SUBSCRIPTION_URL),
    options: {
        timeout: 1000,
        lazy: true,
        reconnect: true,
        connectionParams: {
            jwt: localStorage.getItem('token')
        }
    }
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((item) => {
            if (item && item.extensions) {
                switch (item.extensions.code) {
                    case 'UNAUTHENTICATED':
                        // Clear token
                        try {
                            localStorage.removeItem('token');
                        } catch (e) {}
                        // Redirect to login page
                        let path = [...window.location.href.split('/')];
                        const pathObject = {
                            origin: path[0] + '//' + path[2],
                            pathname: null
                        };
                        const base64redirect = Buffer.from(
                            JSON.stringify(pathObject),
                            'utf-8'
                        ).toString('base64');
                        window.location.replace(
                            `https://www.takkt.io/api/blackmagic/${base64redirect}`
                        );
                        break;
                    default:
                        log.error(
                            `[GraphQL error]: Message: ${item.message}, Location: ${JSON.stringify(
                                item.locations
                            )}, Path: ${item.path}`
                        );
                }
            }
        });
    }

    if (networkError) log.error(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `${token}` : ''
        }
    };
});

const link = from([authLink, errorLink, splitLink]);

const client = new ApolloClient({
    uri: process.env.BACKEND_URL,
    cache: new InMemoryCache({
        typePolicies: {
            Meeting: {
                keyFields: ['publicId'],
                fields: {
                    MeetingEvent: {
                        keyArgs: ['hash']
                    }
                }
            }
        }
    }),
    link: link
});

export default client;
