import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import update from 'immutability-helper';

import Metric from './Metric';

import {
    CHANGE_WIDGET_TITLE,
    GET_DATAITEMS,
    GET_WIDGET,
    REAL_TIME_ITEM
} from '../../graphql/queries';

import { DataItem, DataSource, ItemType } from '../../graphql/generated/graphql';
import calcWidgetValues from '../../util/calcWidgetValues';

interface Props {
    publicId: string;
    boardId: string;
    renderSelection: string;
    inReport: boolean;
    displayMode: string;
    dateRange: { startDate: number | null; endDate: number | null };
    updateMetricView: (
        boardId: string,
        publicId: string,
        renderSelection: string,
        itemType: ItemType,
        inReport: boolean,
        displayMode: string
    ) => void;
    showWarning: (warning: any) => void;
    setDraggable: (v: boolean) => void;
    triggerCombineModal: (triggerPublicId: string) => void;
    triggerMoveModal: (publicId: string, itemType: string) => void;
}

interface Value {
    value: number;
    timestamp: number;
}

const Widget: React.FC<Props> = (props: Props) => {
    const { publicId } = props;

    const [title, setTitle] = useState<string>('');
    const [dataItems, setDataItems] = useState();
    const [values, setValues] = useState<Value[] | undefined | null>();

    const [updateWidgetTitle] = useMutation(CHANGE_WIDGET_TITLE);

    const updateTitle = () => {
        updateWidgetTitle({
            variables: { publicId, newTitle: title }
        }).catch((e) => console.log(e));
    };

    const { loading, error, data, refetch } = useQuery(GET_WIDGET, { variables: { publicId } });

    const [loadDataItems, { loading: dataLoading, data: dataData, subscribeToMore }] = useLazyQuery(
        GET_DATAITEMS
    );

    useEffect(() => {
        if (subscribeToMore) {
            const unsubItem = subscribeToMore({
                document: REAL_TIME_ITEM,
                variables: {
                    publicIds: data.item.dataSources.map((ds: DataSource) => ds.publicId)
                },
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;

                    return update(prev, {
                        $merge: {
                            dataItems: prev.dataItems.map((d: any) => {
                                if (d.publicId === subscriptionData.data.itemUpdated.publicId) {
                                    return {
                                        ...d,
                                        values: subscriptionData.data.itemUpdated.values
                                    };
                                } else {
                                    return d;
                                }
                            })
                        }
                    });
                }
            });

            return () => {
                unsubItem();
            };
        }
    });

    useEffect(() => {
        if (data && data.item) {
            loadDataItems({
                variables: {
                    publicIds: data.item.dataSources.map((ds: DataSource) => ds.publicId)
                }
            });
        }
    }, [data, loadDataItems]);

    useEffect(() => {
        if (dataData && dataData.dataItems) setDataItems(dataData.dataItems);

        // eslint-disable-next-line
    }, [dataData]);

    useEffect(() => {
        if (dataItems) {
            const val = calcWidgetValues(dataItems! as DataItem[], data.item.operation);
            setValues(val);
        }
        // eslint-disable-next-line
    }, [dataItems]);

    return (
        <Metric
            {...props}
            itemType={ItemType.Datawidget}
            values={values}
            updateTitle={updateTitle}
            setTitle={setTitle}
            title={title}
            loading={loading || dataLoading || values === null || values === undefined}
            error={error}
            data={data}
            refetch={refetch}
        />
    );
};

export default Widget;
