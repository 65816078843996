import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { ButtonPrimary, Container, Heading2, Header } from '../StyledComponents';

import Loading from '../elements/Loading';
import CreateBoard from '../modals/CreateBoard';
import List from '../layout/List';

import { CREATE_BOARD, DELETE_BOARD, GET_BOARDS } from '../../graphql/queries';

interface Props {
    showWarning: (warning: any) => void;
}

const Overview: React.FC<Props> = (props: Props) => {
    const { showWarning } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const { loading, error, data, refetch } = useQuery(GET_BOARDS);
    const [createBoardMutation] = useMutation(CREATE_BOARD);
    const [deleteBoardMutation] = useMutation(DELETE_BOARD);

    const createBoard = (name: string, description: string) =>
        createBoardMutation({ variables: { name, description } })
            .then(() => refetch())
            .catch((e) => console.log(e));

    // Doesn't actually delete board, only archives it
    const deleteBoard = (publicId: string) =>
        deleteBoardMutation({
            variables: { publicId }
        })
            .then(() => refetch())
            .catch((e) => console.log(e));

    if (loading) {
        return <Loading />;
    } else if (error) {
        console.log(error);
        return <Loading />;
    }

    const {
        user: { boards }
    } = data;

    return (
        <Container>
            <Header>
                <Heading2 noMargin>Your boards</Heading2>
                <ButtonPrimary onClick={() => setModalOpen(!modalOpen)}>
                    Create new board
                </ButtonPrimary>
            </Header>

            <List boards={boards} deleteBoard={deleteBoard} showWarning={showWarning} />

            {modalOpen && (
                <CreateBoard closeModal={() => setModalOpen(false)} createBoard={createBoard} />
            )}
        </Container>
    );
};

export default Overview;
