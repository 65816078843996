// Calculate progress relative to target value
function progress(values: { value: number }[], target: { value: number }) {
    if (values.length === 0) return 0;

    let div = values[values.length - 1].value / target.value;

    return parseInt((div * 100).toFixed(2));
}

export default progress;
