import React, { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Item from '../elements/Item';
import Widget from '../elements/Widget';

import { ItemType } from '../../graphql/generated/graphql';
import { NoItems } from '../StyledComponents';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Props {
    items: [
        {
            publicId: string;
            inReport: boolean;
            renderSelection: string;
            itemType: ItemType;
            displayMode: string;
        }
    ];
    boardId: string;
    layoutsString: string;
    dateRange: { startDate: number | null; endDate: number | null };
    updateMetricView: (
        boardId: string,
        publicId: string,
        renderSelection: string,
        itemType: ItemType,
        inReport: boolean,
        displayMode: string
    ) => void;
    updateLayout: (boardId: string, layouts: string) => void;
    showWarning: (warning: any) => void;
    triggerCombineModal: (triggerPublicId: string) => void;
    triggerMoveModal: (publicId: string, itemType: string) => void;
}

const Grid: React.FC<Props> = (props: Props) => {
    const {
        boardId,
        items,
        layoutsString,
        dateRange,
        updateMetricView,
        updateLayout,
        showWarning,
        triggerCombineModal,
        triggerMoveModal
    } = props;

    const [draggable, setDraggable] = useState<boolean>(true);
    const [initialBreakpoint, setInitialBreakpoint] = useState<string>('lg');

    const breakpoints: { [key: string]: number } = {
        xxs: 0,
        xs: 550,
        sm: 850,
        md: 1400,
        lg: 1700
    };

    let layouts: any = {};

    if (layoutsString === null || layoutsString === '') {
        // Create a basic layouts object if it doesn't exist yet
        for (let key in breakpoints) {
            layouts[key] = items.map((m) => ({
                i: m.publicId,
                x: 0,
                y: 0,
                h: 1,
                minH: 1,
                maxH: 2,
                w: 1,
                minW: 1,
                maxW: 3
            }));
        }
    } else {
        layouts = JSON.parse(layoutsString);

        for (let breakpoint in layouts) {
            layouts[breakpoint].forEach((l: any) => {
                // For backwards-compatability
                l.isResizable = true;
                l.maxH = 2;
                l.maxW = 3;
            });
        }
    }

    return items.length > 0 ? (
        <ResponsiveGridLayout
            autoSize={true}
            compactType={'horizontal'}
            margin={[25, 25]}
            containerPadding={[0, 0]}
            rowHeight={275}
            isDraggable={draggable}
            isResizable={true}
            isBounded={false}
            breakpoints={breakpoints}
            preventCollision={false}
            cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
            layouts={layouts}
            onLayoutChange={(_currentLayout, allLayouts) =>
                updateLayout(boardId, JSON.stringify(allLayouts))
            }
            onWidthChange={(width) => {
                const sortedBreakpoints = Object.keys(breakpoints).sort(
                    (breakpoint1, breakpoint2) =>
                        breakpoints[breakpoint1] - breakpoints[breakpoint2]
                );

                let breakpoint = sortedBreakpoints[0];
                for (let i = 0; i < sortedBreakpoints.length; i++) {
                    const currentBreakpoint = sortedBreakpoints[i];
                    const nextBreakpoint = sortedBreakpoints[i + 1];

                    if (
                        typeof nextBreakpoint === 'undefined' ||
                        (breakpoints[currentBreakpoint] <= width &&
                            width <= breakpoints[nextBreakpoint])
                    ) {
                        breakpoint = currentBreakpoint;
                        break;
                    }
                }

                setInitialBreakpoint(breakpoint);
            }}
        >
            {items.map((item) => {
                const correspondingLayout = layouts[initialBreakpoint].filter(
                    (l: any) => l.i === item.publicId
                )[0];

                const renderSelection =
                    correspondingLayout === undefined ||
                    (correspondingLayout.w === 1 && correspondingLayout.h === 1)
                        ? 'SIMPLE'
                        : 'LINEGRAPH';

                return (
                    <div key={item.publicId}>
                        {item.itemType === ItemType.Dataitem ? (
                            <Item
                                {...item}
                                renderSelection={renderSelection}
                                boardId={boardId}
                                updateMetricView={updateMetricView}
                                showWarning={showWarning}
                                setDraggable={setDraggable}
                                triggerCombineModal={triggerCombineModal}
                                triggerMoveModal={triggerMoveModal}
                                dateRange={dateRange}
                            />
                        ) : item.itemType === ItemType.Datawidget ? (
                            <Widget
                                boardId={boardId}
                                {...item}
                                renderSelection={renderSelection}
                                updateMetricView={updateMetricView}
                                showWarning={showWarning}
                                setDraggable={setDraggable}
                                triggerCombineModal={triggerCombineModal}
                                triggerMoveModal={triggerMoveModal}
                                dateRange={dateRange}
                            />
                        ) : null}
                    </div>
                );
            })}
        </ResponsiveGridLayout>
    ) : (
        <NoItems>Add a new metric to start measuring your performance. ↗️</NoItems>
    );
};

export default Grid;
