import styled from 'styled-components';
import { Link } from 'react-router-dom';
import chevron from '../assets/chevron-white.svg';

/**
 * Layout
 */
const Container = styled.div`
    position: relative;
    width: 95%;
    height: 100%;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        width: 92.5%;
    }
`;

interface FlexWrapperProps {
    col?: boolean;
    auto?: boolean;
    autoHeight?: boolean;
    stretch?: boolean;
    padding?: boolean;
}

const FlexWrapper = styled.div<FlexWrapperProps>`
    display: flex;
    width: ${(props) => (props.auto ? 'auto' : '100%')};
    height: ${(props) => (props.autoHeight ? 'auto' : '100%')};
    flex-direction: ${(props) => (props.col ? 'column' : 'row')};
    align-items: ${(props) => (props.col ? 'flex-start' : 'center')};
    justify-content: ${(props) => (props.stretch ? 'stretch' : 'space-between')};
`;

interface CardProps {
    highlighted?: boolean;
}

const Card = styled.div<CardProps>`
    position: relative;
    height: 100%;
    background-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: ${(props) => props.theme.boxShadow1};
    border-radius: ${(props) => props.theme.borderRadius2};
    border: ${(props) => (props.theme.darkTheme ? 'none' : props.theme.border)};
    padding: 2.5rem;

    div {
        overflow: hidden;
    }

    ${(props) =>
        props.highlighted &&
        `
        
        background-color: ${props.theme.colors.primary};
        
        div::after {
            color: ${props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.black};
        }
    `}
`;

/**
 * Buttons
 */
interface ButtonProps {
    small?: boolean;
    plusIcon?: boolean;
}

const Button = styled.button<ButtonProps>`
    position: relative;
    outline: none;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius1};
    background-color: ${(props) => props.theme.colors.lightGrey};
    border: none;
    color: ${(props) => props.theme.colors.black};
    padding: ${(props) => (props.small ? '1.2rem 1.5rem' : '1.3rem 1.5rem')};
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.9;
    }

    ${(props) =>
        props.plusIcon &&
        `
        padding-left: 4rem;

        &::before {
            content: '+';
            font-family: Times New Roman, sans-serif;
            font-size: 3rem;
            font-weight: 300;
            padding-right: 1rem;
            position: absolute;
            top: 50%;
            left: 1.5rem;
            transform: translateY(-50%);
        }
    `}
`;

const ButtonPrimary = styled(Button)`
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) =>
        props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.black};
`;

const ButtonError = styled(Button)`
    background-color: ${(props) => props.theme.colors.error};
    color: ${(props) =>
        props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.black};
`;

/**
 * Typography
 */
interface Heading2Props {
    noMargin?: boolean;
}

const Heading2 = styled.h2<Heading2Props>`
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: ${(props) => (props.noMargin ? '0' : '1rem')};
    color: ${(props) => props.theme.colors.white};

    @media screen and (max-width: 800px) {
        font-size: 2.8rem;
    }
`;

const EditableHeading2 = styled.input`
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.colors.white};
    background: transparent;
    outline: none;
    border: none;

    @media screen and (max-width: 800px) {
        font-size: 2.8rem;
    }
`;

const Heading3 = styled.h3`
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: ${(props) => props.theme.spaceSmall};
    color: ${(props) => props.theme.colors.white};
`;

const Heading4 = styled.h4`
    font-size: 1.8rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    width: 100%;
`;

const EditableHeading4 = styled.input`
    font-size: 1.7rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-weight: 500;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.75;
    }
`;

const EditableParagraph = styled.input`
    color: ${(props) => props.theme.colors.middleGrey};
    font-size: 1.6rem;
    background: transparent;
    outline: none;
    border: none;
`;

/**
 * Navbar
 */
const NavWrapper = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.darkGrey};
    border-bottom: ${(props) =>
        props.theme.darkTheme ? '1px solid rgba(0, 0, 0, 0.2)' : props.theme.border};
    height: 9rem;
    z-index: 1;

    @media screen and (max-width: 800px) {
        height: 8rem;
    }
`;

const Logo = styled.h1`
    font-size: 3.5rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};

    span {
        color: ${(props) => props.theme.colors.primary};
    }

    @media screen and (max-width: 800px) {
        font-size: 3rem;
    }
`;

const Profile = styled.div`
    font-size: 2.5rem;
    font-weight: 600;
    height: 5rem;
    width: 5rem;
    text-align: center;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) =>
        props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.black};
    border-radius: ${(props) => props.theme.borderRadius2};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${(props) => props.theme.spaceMedium};
    cursor: pointer;

    @media screen and (max-width: 800px) {
        font-size: 2.2rem;
        height: 4.5rem;
        width: 4.5rem;
    }
`;

const NavLink = styled(StyledLink)`
    font-size: 1.7rem;
    font-weight: 500;
`;

const DumbNavLink = styled.p`
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-weight: 500;
    transition: 0.2s opacity;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
`;

interface NavbarMenuProps {
    show: boolean;
}

const NavbarMenu = styled.div<NavbarMenuProps>`
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.3s;
    position: absolute;
    right: 0;
    top: 8rem;
    z-index: 1000;
    padding: ${(props) => props.theme.spaceSmall};
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px) brightness(100%);
    background-color: ${(props) =>
        props.theme.darkTheme
            ? `${props.theme.colors.middleGrey}22`
            : `${props.theme.colors.black}88`};
    border-radius: ${(props) => props.theme.borderRadius2};
    box-shadow: ${(props) => props.theme.boxShadow1};
    border: ${(props) => props.theme.border};
    width: auto;

    p {
        color: ${(props) => props.theme.colors.white};
        padding: 5px 0;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &:last-child {
            color: ${(props) => props.theme.colors.error};
            cursor: pointer;
            font-weight: 700;
        }
    }

    span {
        color: ${(props) => props.theme.colors.white} !important;
        font-weight: 400 !important;
    }

    > div {
        margin-bottom: 1.5rem;
    }
`;

const ReportNavMenu = styled(FlexWrapper)`
    > div {
        margin: 0 2rem 0 0;
    }
`;

/**
 * Header
 */
interface HeaderProps {
    actions?: boolean;
}

const Header = styled(FlexWrapper)<HeaderProps>`
    width: 100%;
    height: auto;
    padding: ${(props) => props.theme.spaceMedium} 0;

    > div {
        width: auto;
        flex-grow: 1;
    }

    form,
    input {
        width: 100%;
    }

    ${(props) =>
        props.actions &&
        `
        justify-content: stretch;
        
        @media screen and (max-width: 1450px) {
            display: grid;
            grid-template-columns: 1fr;
            gap: 3rem;
        }
    `}

    @media screen and (max-width: 800px) {
        padding: 2.5rem 0;
    }
`;

const HeaderLink = styled.span`
    margin-right: ${(props) => props.theme.spaceSmall};
    color: ${(props) => props.theme.colors.lightGrey};
    cursor: pointer;
`;

const HeaderLinkWrapper = styled(FlexWrapper)`
    justify-content: flex-end;

    @media screen and (max-width: 1450px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        > a,
        > span {
            width: 100%;
            text-align: center;
            border-radius: ${(props) => props.theme.borderRadius1};
            background-color: ${(props) => props.theme.colors.darkGrey};
            padding: 1.5rem;
            transition: all 0.2s;

            &:hover {
                background-color: ${(props) => props.theme.colors.middleGrey}40;
            }
        }

        span {
            margin: 0;
        }

        button {
            padding: 1.5rem;
            width: 100%;
            height: auto;
        }
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;

        button::before {
            display: none;
        }
    }
`;

/**
 * General components
 */
interface HelpIconProps extends ButtonProps {
    bottom?: string | number | null;
    right?: string | number | null;
    position?: string | null;
}

const HelpIcon = styled(ButtonPrimary)<HelpIconProps>`
    position: ${(props) => props.position || 'fixed'};
    bottom: ${(props) => props.bottom || props.theme.spaceMedium};
    right: ${(props) => props.right || props.theme.spaceMedium};
    z-index: 1;
    height: 5rem;
    width: 5rem;
    text-align: center;
    padding: 0;
`;

interface CheatsheetProps {
    visible?: boolean;
}

const Cheatsheet = styled.div<CheatsheetProps>`
    position: fixed;
    bottom: 7.5rem;
    right: 3rem;
    z-index: 1;
    padding: 2rem;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px) brightness(100%);
    background-color: ${(props) => props.theme.colors.black};
    border-radius: ${(props) => props.theme.borderRadius2};
    box-shadow: ${(props) => props.theme.boxShadow1};
    width: 30rem;
    max-width: 95vw;
    font-size: 1.5rem;

    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;

    ${(props) =>
        props.visible &&
        `
        visibility: visible;
        opacity: 1;
    `}

    span {
        display: block;
        color: ${(props) => props.theme.colors.white}CC;
        font-weight: 500;
        margin-bottom: 2px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    h4 {
        margin-bottom: 1.8rem;
    }
`;

const SmallIcon = styled.div`
    cursor: pointer;
    border: 2px solid ${(props) => props.theme.colors.middleGrey};
    border-radius: 100%;
    width: 2.3rem;
    height: 2.3rem;
    text-align: center;
    fontsize: 1.4rem;
    margin-bottom: -5px;
    padding-left: 1px;
`;

interface FeedbackModalProps {
    visible?: boolean;
}

const FeedbackModal = styled.div<FeedbackModalProps>`
    position: fixed;
    bottom: 10rem;
    right: ${(props) => props.theme.spaceMedium};
    z-index: 1;
    padding: 2rem;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px) brightness(100%);
    background-color: ${(props) => props.theme.colors.darkGrey};
    border-radius: ${(props) => props.theme.borderRadius2};
    box-shadow: ${(props) => props.theme.boxShadow1};
    border: ${(props) => props.theme.border};
    width: 35rem;
    max-width: 95vw;

    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;

    ${(props) =>
        props.visible &&
        `
        visibility: visible;
        opacity: 1;
    `}

    span {
        display: block;
        color: ${(props) => props.theme.colors.white}CC;
        font-weight: 500;
        margin-bottom: 2px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    h4 {
        margin-bottom: 1.8rem;
    }
`;

const VerticalDots = styled.div`
    cursor: pointer;
    color: ${(props) => props.theme.colors.middleGrey};
    transition: color 0.2s;
    padding: 0 5px 0 10px;
    margin-right: -10px;

    &::after {
        content: '\\2807';
        font-size: 3rem;
        line-height: 1;
    }

    &:hover {
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

const NoItems = styled.div`
    width: 100%;
    text-align: center;
    padding: ${(props) => props.theme.spaceLarge} ${(props) => props.theme.spaceSmall};
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.middleGrey};
`;

/**
 * Modals
 */

const ModalContainer = styled.form`
    position: absolute;
    top: 11rem;
    right: 0;

    @media screen and (max-width: 800px) {
        top: 9rem;
    }
`;

const ConfirmationWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ConfirmationMessage = styled.p`
    margin-bottom: ${(props) => props.theme.spaceSmall};
    line-height: 1.5;
`;

const Modal = styled.div`
    position: relative;
    padding: 3rem;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px) brightness(100%);
    background-color: ${(props) =>
        props.theme.darkTheme ? props.theme.colors.darkGrey : props.theme.colors.black}EC;
    border-radius: ${(props) => props.theme.borderRadius2};
    box-shadow: ${(props) => props.theme.boxShadow1};
    border: ${(props) => props.theme.border};
    width: 45rem;
    max-width: 95vw;

    input,
    textarea,
    select {
        background-color: ${(props) =>
            props.theme.darkTheme ? props.theme.colors.black : props.theme.colors.darkGrey};
    }

    @media screen and (max-width: 800px) {
        padding: 2.5rem;
    }
`;

const CloseIcon = styled.div`
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }

    &::before,
    &::after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 25px;
        width: 2px;
        border-radius: 3px;
        background-color: ${(props) => props.theme.colors.white};
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
`;

const FullscreenOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: ${(props) =>
        props.theme.darkTheme
            ? `${props.theme.colors.darkGrey}A0`
            : `${props.theme.colors.middleGrey}A0`};
    transition: all 0.2s;
`;

const ModalError = styled.p`
    color: ${(props) => props.theme.colors.error};
    padding-bottom: ${(props) => props.theme.spaceSmall};
`;

/**
 * Inputs
 */
interface InputProps {
    spaceRight?: boolean;
    small?: boolean;
}

const Input = styled.input<InputProps>`
    padding: ${(props) => (props.small ? '1.3rem' : '1.4rem 1.5rem')};
    border: none;
    border-radius: ${(props) => props.theme.borderRadius1};
    width: 100%;
    outline: none;
    background-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: ${(props) => props.theme.boxShadow1};
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: ${(props) => props.theme.spaceSmall};
    margin-right: ${(props) => (props.spaceRight ? props.theme.spaceSmall : 0)};
    transition: all 0.2s;

    &::placeholder {
        color: ${(props) => props.theme.colors.middleGrey}90;
    }

    &:focus {
        box-shadow: ${(props) => props.theme.boxShadowInset};
    }
`;

const InputWrapper = styled.div`
    width: 100%;

    label {
        display: block;
        font-weight: 400;
        margin-bottom: 5px;
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

interface TextAreaProps {
    darker?: boolean;
}

const TextArea = styled.textarea<TextAreaProps>`
    padding: 1.4rem 1.5rem;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius1};
    width: 100%;
    outline: none;
    background-color: ${(props) =>
        props.theme.darkTheme && props.darker
            ? props.theme.colors.black
            : props.theme.colors.darkGrey};
    box-shadow: ${(props) => props.theme.boxShadow1};
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: ${(props) => props.theme.spaceSmall};
    font-family: ${(props) => props.theme.fontFamily};
    resize: none;
    height: 13rem;

    &::placeholder {
        color: ${(props) => props.theme.colors.middleGrey}90;
    }

    &:focus {
        box-shadow: ${(props) => props.theme.boxShadowInset};
    }
`;

const Select = styled.select`
    padding: 1.4rem 1.5rem;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius1};
    width: 100%;
    outline: none;
    background-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: ${(props) => props.theme.boxShadow1};
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors.white};
    margin-bottom: ${(props) => props.theme.spaceSmall};

    display: table-row;

    option {
        display: table-cell;
    }

    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url(${chevron});
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1.5rem);
    background-position-y: 50%;

    &::placeholder {
        color: ${(props) => props.theme.colors.middleGrey};
    }
`;

const SelectWrapper = styled(InputWrapper)`
    &:nth-child(2) {
        margin-left: ${(props) => props.theme.spaceSmall};
    }
`;

const ToggleWrapper = styled.div`
    margin-bottom: ${(props) => props.theme.spaceSmall};

    span {
        margin-left: 1rem;
        color: ${(props) => props.theme.colors.lightGrey};
        font-weight: 500;
        transition: all 0.3s;
    }
`;

const RadioButtons = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${(props) => props.theme.spaceSmall};
    border-radius: ${(props) => props.theme.borderRadius1};
    box-shadow: ${(props) => props.theme.boxShadow1};

    div {
        display: flex;
        justify-content: center;
        background-color: ${(props) =>
            props.theme.darkTheme ? props.theme.colors.black : props.theme.colors.darkGrey};
        box-shadow: ${(props) => props.theme.boxShadowInset};
        overflow: hidden;
        padding: 3px;
        text-align: center;
        font-weight: 600;

        &:first-child {
            border-top-left-radius: ${(props) => props.theme.borderRadius1};
            border-bottom-left-radius: ${(props) => props.theme.borderRadius1};
        }

        &:last-child {
            border-top-right-radius: ${(props) => props.theme.borderRadius1};
            border-bottom-right-radius: ${(props) => props.theme.borderRadius1};
        }

        label {
            width: 100%;
            height: 100%;
            padding: 1.4rem 1.5rem;
            font-weight: 600;
            border-radius: 8px;
            margin: 0;
            transition: 0.1s;
            cursor: pointer;
        }

        input {
            appearance: none;
        }

        input:checked + label {
            background-color: ${(props) =>
                props.theme.darkTheme ? props.theme.colors.darkGrey : props.theme.colors.black};
        }
    }
`;

const DateRangeWrapper = styled.div`
    @media screen and (max-width: 1450px) {
        display: none;
    }

    margin-right: ${(props) => props.theme.spaceSmall};

    .DateRangePickerInput {
        background-color: transparent;
        border: ${(props) => props.theme.border};
        border-color: ${(props) => props.theme.colors.middleGrey}90;
        border-radius: ${(props) => props.theme.borderRadius1};

        .DateInput {
            background-color: transparent;

            input {
                background-color: transparent;
                border: none;
                padding: 1.2rem 1.5rem;
                line-height: 1;
                color: ${(props) => props.theme.colors.lightGrey};
                font-family: ${(props) => props.theme.fontFamily};
                font-size: 1.6rem;
                font-weight: 400;
            }
        }

        .DateRangePickerInput_arrow {
            path {
                fill: ${(props) => props.theme.colors.lightGrey};
            }
        }
    }

    .DateRangePicker_picker {
        background-color: ${(props) =>
            props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.darkGrey};
        border-radius: ${(props) => props.theme.borderRadius1};

        > div {
            background-color: ${(props) =>
                props.theme.darkTheme ? props.theme.colors.white : props.theme.colors.darkGrey};
            border-radius: ${(props) => props.theme.borderRadius1};
        }

        .CalendarDay__selected_span {
            background: ${(props) => props.theme.colors.primary}A0;
            color: ${(props) => props.theme.colors.white};
            border-color: ${(props) => props.theme.colors.primary}30;
        }

        .CalendarDay__selected {
            background: ${(props) => props.theme.colors.primary};
            color: ${(props) => props.theme.colors.white};
            border-color: ${(props) => props.theme.colors.primary}30;
        }

        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
            background: ${(props) => props.theme.colors.primary}70;
            color: ${(props) => props.theme.colors.white};
            border-color: ${(props) => props.theme.colors.primary}30;
        }
    }
`;

/**
 * Boards List
 */

const LastUpdated = styled.div`
    color: ${(props) => props.theme.colors.white};
`;

const Handle = styled.div`
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    padding: 1rem;
    margin-right: ${(props) => props.theme.spaceSmall};
    margin-top: -4px;
    cursor: move;
`;

const ListItem = styled.div`
    display: grid;
    grid-template-columns: 5fr 2fr 1fr 0.5fr;
    justify-items: center;
    align-items: center;
    gap: 2rem;

    background-color: ${(props) => props.theme.colors.darkGrey};
    box-shadow: ${(props) => props.theme.boxShadow1};
    border-radius: ${(props) => props.theme.borderRadius1};
    border: ${(props) => (props.theme.darkTheme ? 'none' : props.theme.border)};
    color: ${(props) => props.theme.colors.lightGrey};
    padding: 3rem;
    transition: all 0.2s;

    &:hover {
        opacity: 0.9;
    }

    button {
        justify-self: end;
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: 2fr 1fr;

        > div {
            &:nth-child(2),
            &:nth-child(3) {
                display: none;
            }
        }
    }
`;

/**
 * Metrics
 */

interface MetricTopProps {
    report?: boolean;
}

const MetricTop = styled.div<MetricTopProps>`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: ${(props) => (props.report ? 'auto' : '30px auto 60px')};
    gap: 0.5rem;
    overflow: visible !important;
    margin-bottom: 1.5rem;

    div:last-child {
        width: auto;
        height: auto;
        justify-self: end;
        align-self: start;
    }
`;

const DisplayMode = styled.p`
    font-size: 1.5rem;
    margin-top: 2px;
`;

const AddValue = styled.p`
    font-size: 3.4rem;
    font-weight: 300;
    cursor: pointer;
    padding: 5px;
    margin-top: -13px;
    transition: all 0.1s;

    &:hover {
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

interface MetricValueProps {
    highlighted?: boolean;
}

const MetricValue = styled.p<MetricValueProps>`
    display: flex;
    align-items: center;
    font-size: 7rem;
    font-weight: 700;
    color: ${(props) =>
        !props.theme.darkTheme && props.highlighted
            ? props.theme.colors.darkGrey
            : props.theme.colors.white};
`;

const MetricNoValue = styled.p<MetricValueProps>`
    font-size: 3.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.middleGrey};
`;

const MetricProgress = styled.div`
    position: relative;
    width: 100%;
    height: 7.5rem;
`;

interface MetricChangeProps {
    down?: boolean;
    equal?: boolean;
    highlighted?: boolean;
    visible?: boolean;
}

const MetricChange = styled.div<MetricChangeProps>`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;

    ${(props) =>
        props.visible &&
        `
        visibility: visible;
        opacity: 1;
        top: 2rem;
    `}

    color: ${(props) =>
        props.down
            ? props.theme.colors.error
            : props.equal
            ? props.highlighted
                ? props.theme.darkTheme
                    ? props.theme.colors.lightGrey
                    : props.theme.colors.black
                : props.theme.colors.middleGrey
            : props.theme.colors.success};

    span {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: ${(props) => (props.down ? '6px solid ' + props.theme.colors.error : 'none')};
        border-bottom: ${(props) =>
            props.down ? 'none' : '6px solid ' + props.theme.colors.success};
        ${(props) => (props.equal ? 'border: none;' : '')}
        margin-right: 5px;
    }
`;

interface ProgressBarWrapperProps {
    moveToTop?: boolean;
}

const ProgressBarWrapper = styled(FlexWrapper)<ProgressBarWrapperProps>`
    position: absolute;
    top: 5rem;
    height: auto;
    transition: all 0.3s;

    ${(props) =>
        props.moveToTop &&
        `
        top: 0;
    `}
`;

interface ProgressBarProps {
    percentage: number;
    highlighted?: boolean;
}

const ProgressBar = styled.div<ProgressBarProps>`
    width: auto;
    max-width: 100%;
    flex: 1;
    margin-right: 8px;
    transition: all 0.3s;

    div {
        position: relative;
        flex: 1;
        width: 100%;
        height: 9px;
        border-radius: 25px;
        background-color: ${(props) =>
            props.highlighted
                ? props.theme.darkTheme
                    ? `${props.theme.colors.middleGrey}A0`
                    : `${props.theme.colors.black}`
                : props.theme.colors.black};
        overflow: hidden;
        transition: all 0.3s;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 25px;
        width: ${(props) => props.percentage}%;
        height: 100%;
        background-color: ${(props) =>
            props.highlighted
                ? props.theme.darkTheme
                    ? props.theme.colors.white
                    : props.theme.colors.darkGrey
                : props.theme.colors.primary};
        transition: all 0.3s;
        z-index: 1;
    }
`;

interface MetricTargetProps {
    visible?: boolean;
}

const MetricTarget = styled.div<MetricTargetProps>`
    position: absolute;
    top: 5rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;

    ${(props) =>
        props.visible &&
        `
            visibility: visible;
            opacity: 1;
            top: 1.5rem;
    `}
`;

const MetricTargetIcon = styled.div`
    img {
        height: 2rem;
    }

    svg {
        path {
            fill: ${(props) => props.theme.colors.primary};
        }
    }
`;

interface MetricTargetValueProps {
    noTarget?: boolean;
    highlighted?: boolean;
}

const MetricTargetValue = styled.div<MetricTargetValueProps>`
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.lightGrey};
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2px;

    img {
        height: 0.75em;
        margin-right: 0.5rem;
    }
`;

const MetricNoTarget = styled.p`
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
`;

interface OptionsMenuProps {
    show: boolean;
}

const OptionsMenu = styled.form<OptionsMenuProps>`
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.3s;
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: ${(props) => props.theme.spaceSmall};
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px) brightness(100%);
    background-color: ${(props) => props.theme.colors.black};
    border-radius: ${(props) => props.theme.borderRadius2};
    border: ${(props) => (props.theme.darkTheme ? 'none' : props.theme.border)};
    box-shadow: ${(props) => props.theme.boxShadow1};
    width: auto;
    z-index: 1;

    p {
        color: ${(props) => props.theme.colors.white};
        cursor: pointer;
        padding: 0.75rem 0;
        transition: all 0.2s;
        padding-right: 3px;

        &:first-child {
            margin-top: -0.75rem;
        }

        &:last-child {
            color: ${(props) => props.theme.colors.error};
            margin-bottom: -0.75rem;
        }

        &:hover {
            padding-left: 3px;
            padding-right: 0px;
        }
    }

    input,
    button {
        max-width: 30rem;
        display: block;
    }
`;

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CSVInputWrapper = styled.div`
    width: 100%;
    margin-bottom: 2rem;

    > div {
        padding: 3rem !important;
        border-color: ${(props) => props.theme.colors.middleGrey} !important;
    }
`;

export {
    Button,
    ButtonPrimary,
    ButtonError,
    Card,
    Container,
    FlexWrapper,
    EditableHeading2,
    Heading2,
    Heading3,
    Heading4,
    StyledLink,
    VerticalDots,
    Modal,
    Input,
    TextArea,
    CloseIcon,
    LastUpdated,
    Handle,
    ListItem,
    ModalContainer,
    NavWrapper,
    Logo,
    Profile,
    NavLink,
    Header,
    HeaderLink,
    HeaderLinkWrapper,
    HelpIcon,
    MetricValue,
    MetricNoValue,
    MetricChange,
    ProgressBar,
    MetricTargetValue,
    OptionsMenu,
    NavbarMenu,
    EditableParagraph,
    EditableHeading4,
    ToggleWrapper,
    SelectWrapper,
    Select,
    InputWrapper,
    RadioButtons,
    LoadingWrapper,
    ConfirmationWrapper,
    ConfirmationMessage,
    NoItems,
    FullscreenOverlay,
    ModalError,
    ReportNavMenu,
    MetricTop,
    DisplayMode,
    AddValue,
    MetricProgress,
    MetricTargetIcon,
    MetricTarget,
    MetricNoTarget,
    ProgressBarWrapper,
    CSVInputWrapper,
    FeedbackModal,
    DumbNavLink,
    DateRangeWrapper,
    Cheatsheet,
    SmallIcon
};
