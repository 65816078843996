import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

import { FlexWrapper, Heading4, LastUpdated, Handle, ListItem, NoItems } from '../StyledComponents';

import RemoveIcon from '../../assets/remove.svg';

import { UPDATE_BOARD_ORDER } from '../../graphql/queries';

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 4,
    margin: `0 0 ${grid}px 0`,

    // styles we need to apply on draggables
    ...draggableStyle
});

//const getListStyle = (isDraggingOver: boolean) => ({});

interface Props {
    boards: [
        {
            publicId: string;
            participants: [
                {
                    nickname: string;
                }
            ];
            name: string;
            description: string;
            dateUpdated: number;
            items: [
                {
                    publicId: string;
                }
            ];
        }
    ];
    deleteBoard: (publicId: string) => void;
    showWarning: (warning: any) => void;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const List: React.FC<Props> = (props: Props) => {
    const { deleteBoard, showWarning } = props;

    const [boards, setBoards] = useState<Props['boards']>(props.boards);

    useEffect(() => setBoards(props.boards), [props.boards]);

    const [updateBoardOrder] = useMutation(UPDATE_BOARD_ORDER);

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        const oldBoards = [...boards];

        const newBoards = reorder(boards, result.source.index, result.destination!.index);

        setBoards(newBoards as Props['boards']);

        updateBoardOrder({
            variables: {
                from: result.source.index,
                to: result.destination.index
            }
        })
            .then(() => {})
            .catch(() => setBoards(oldBoards as any));
    }

    return boards.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'allBoards'}>
                {(provided, _snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {boards.map((b, index) => (
                            <Draggable key={b.publicId} draggableId={b.publicId} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <ListItem key={index.toString()}>
                                            <ReactTooltip
                                                id={`list-item-tooltip${b.publicId}`}
                                                type={
                                                    window.localStorage.getItem('theme') &&
                                                    window.localStorage.getItem('theme') === 'light'
                                                        ? 'dark'
                                                        : 'light'
                                                }
                                            />

                                            <FlexWrapper>
                                                <Handle
                                                    data-tip='Drag to reorder'
                                                    data-for={`list-item-tooltip${b.publicId}`}
                                                    data-delay-show={300}
                                                    className='handle'
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    :
                                                </Handle>

                                                <Heading4
                                                    data-tip='Click to open'
                                                    data-for={`list-item-tooltip${b.publicId}`}
                                                    data-delay-show={300}
                                                >
                                                    <Link to={`/board/${b.publicId}`}>
                                                        {b.name}
                                                    </Link>
                                                </Heading4>
                                            </FlexWrapper>

                                            <FlexWrapper>
                                                {b.participants.map(
                                                    (p, i) =>
                                                        p.nickname +
                                                        (i === b.participants.length - 1
                                                            ? ''
                                                            : ', ')
                                                )}
                                            </FlexWrapper>

                                            <LastUpdated>
                                                {new Date(b.dateUpdated).toLocaleDateString()}
                                            </LastUpdated>

                                            <img
                                                src={RemoveIcon}
                                                alt='Remove board'
                                                height='25'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    showWarning({
                                                        title: 'Warning',
                                                        message:
                                                            'This board will permanently be removed from your list.',
                                                        button: 'Confirm',
                                                        error: true,
                                                        action: () => deleteBoard(b.publicId)
                                                    })
                                                }
                                            />
                                        </ListItem>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        <NoItems>Click the button in the top right corner to create your first board. ↗️</NoItems>
    );
};

export default List;
