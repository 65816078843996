import React, { useState } from 'react';

import ModalWrapper from './ModalWrapper';

import {
    FlexWrapper,
    ButtonPrimary,
    Heading3,
    Modal,
    Input,
    TextArea,
    CloseIcon,
    ModalContainer,
    ModalError
} from '../StyledComponents';

interface Props {
    closeModal: () => void;
    createBoard: (name: string, description: string) => void;
}

const CreateBoard: React.FC<Props> = (props: Props) => {
    const { closeModal, createBoard } = props;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    return (
        <ModalWrapper onClose={closeModal}>
            <ModalContainer
                onSubmit={(e) => {
                    e.preventDefault();
                    if (name.trim() !== '') {
                        createBoard(name, description);

                        setName('');
                        setDescription('');
                        closeModal();
                    } else {
                        setError('Please add a name for your board.');
                    }
                }}
            >
                <Modal>
                    <CloseIcon onClick={() => closeModal()} />
                    <Heading3>Create new board</Heading3>

                    <FlexWrapper col>
                        <Input
                            placeholder='Title'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <TextArea
                            placeholder='Description (optional)'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        {error.length > 0 && <ModalError>{error}</ModalError>}

                        <ButtonPrimary>Create</ButtonPrimary>
                    </FlexWrapper>
                </Modal>
            </ModalContainer>
        </ModalWrapper>
    );
};

export default CreateBoard;
