import { DefaultTheme } from 'styled-components';

const darkTheme: DefaultTheme = {
    darkTheme: true,

    fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    border: '1px solid rgba(200, 200, 200, 0.2)',
    borderRadius1: '8px',
    borderRadius2: '12px',

    spaceSmall: '2rem',
    spaceMedium: '4rem',
    spaceLarge: '6rem',

    boxShadow1: '0 0 10px rgba(20, 20, 20, 0.2)',
    boxShadowInset: 'inset 0 -5px 45px rgba(50, 50, 50, 0.2), 0 1px 1px rgba(150, 150, 150, 0.1)',

    colors: {
        primary: '#2D55C8',
        error: '#FF4848',
        success: '#43CA5B',
        black: '#171719',
        darkGrey: '#202124',
        middleGrey: '#87898B',
        lightGrey: '#AEAEAE',
        white: '#FCFCFC'
    }
};

const lightTheme: DefaultTheme = {
    darkTheme: false,

    fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    border: '1px solid rgba(150, 150, 150, 0.2)',
    borderRadius1: '8px',
    borderRadius2: '12px',

    spaceSmall: '2rem',
    spaceMedium: '4rem',
    spaceLarge: '6rem',

    boxShadow1: '0 0 10px rgba(200, 200, 200, 0.2)',
    boxShadowInset: 'none',

    colors: {
        primary: '#0F5EF7',
        error: '#EF3737',
        success: '#43CA7B',
        black: '#F3F3F6',
        darkGrey: '#FEFEFE',
        middleGrey: '#A2A8B3',
        lightGrey: '#858585',
        white: '#111E2C'
    }
};

export { lightTheme, darkTheme };
