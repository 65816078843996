import React, { useState } from 'react';
import moment from 'moment';

import LineChart from './LineChart';

import TargetIcon from '../../assets/target.svg';

import {
    Card,
    Heading4,
    FlexWrapper,
    MetricValue,
    MetricNoValue,
    MetricChange,
    MetricTargetValue,
    ProgressBar,
    MetricProgress,
    MetricTargetIcon,
    MetricTarget,
    MetricNoTarget,
    ProgressBarWrapper,
    MetricTop,
    DisplayMode
} from '../StyledComponents';

import { DataItem } from '../../graphql/generated/graphql';

import percIncrease from '../../util/percIncrease';
import progress from '../../util/progress';
import calcWidgetValues from '../../util/calcWidgetValues';
import formatNumber from '../../util/formatNumber';

interface Props {
    data: any;
    renderSelection: string;
    displayMode: string;
    dateRange: { startDate: number | null; endDate: number | null };
}

const Widget: React.FC<Props> = (props: Props) => {
    const {
        data: { name, unit, target, resolvedDataSources, operation },
        renderSelection,
        displayMode,
        dateRange
    } = props;

    const [openTarget, setOpenTarget] = useState(false);

    const values = calcWidgetValues(resolvedDataSources! as DataItem[], operation);

    let renderValues = values.map((v: any) => ({ ...v }));

    // Only show values in specified time interval
    renderValues = renderValues.filter((v: any) => {
        const { startDate, endDate } = dateRange;

        if (startDate !== null && endDate !== null) {
            const min = moment(startDate * 1000)
                .startOf('day')
                .toDate()
                .getTime();
            const max = moment(endDate * 1000)
                .endOf('day')
                .toDate()
                .getTime();

            return v.timestamp * 1000 >= min && v.timestamp * 1000 <= max;
        } else {
            return true;
        }
    });

    if (displayMode === 'SUM') {
        let sum = 0;
        renderValues = renderValues.map((v: { value: number }) => {
            sum += v.value;
            return { ...v, value: parseFloat(sum.toFixed(2)) };
        });
    }

    return (
        <Card>
            <FlexWrapper col>
                <MetricTop report>
                    <form>
                        <Heading4>{name}</Heading4>
                        <DisplayMode>
                            {displayMode === 'SINGLE'
                                ? renderSelection === 'SIMPLE'
                                    ? 'Last entry'
                                    : 'Single values'
                                : 'All-time sum'}
                        </DisplayMode>
                    </form>
                </MetricTop>

                {renderSelection === 'SIMPLE' ? (
                    <FlexWrapper col>
                        {renderValues.length > 0 ? (
                            <MetricValue>
                                {formatNumber(renderValues[renderValues.length - 1].value)}
                                {unit}
                            </MetricValue>
                        ) : (
                            <MetricNoValue>No values were added yet</MetricNoValue>
                        )}

                        <MetricProgress
                            onMouseEnter={() => setOpenTarget(true)}
                            onMouseLeave={() => setOpenTarget(false)}
                        >
                            <MetricChange
                                down={percIncrease(renderValues) < 0}
                                equal={percIncrease(renderValues) === 0}
                                visible={
                                    renderValues.length > 0 &&
                                    (!target || !target.value || !openTarget)
                                }
                            >
                                <span />
                                {percIncrease(renderValues)}% since last entry
                            </MetricChange>

                            {target && target.value ? (
                                <>
                                    <ProgressBarWrapper moveToTop={openTarget}>
                                        <ProgressBar percentage={progress(renderValues, target)}>
                                            <div>
                                                <span />
                                            </div>
                                        </ProgressBar>
                                        {openTarget ? (
                                            <p>{progress(renderValues, target)}%</p>
                                        ) : (
                                            <MetricTargetIcon>
                                                <img src={TargetIcon} alt='Target' />
                                            </MetricTargetIcon>
                                        )}
                                    </ProgressBarWrapper>

                                    <MetricTarget visible={openTarget}>
                                        <MetricTargetValue>
                                            <img src={TargetIcon} alt='Target' />
                                            {formatNumber(target.value)}
                                            {unit}
                                        </MetricTargetValue>
                                        <p>
                                            by{' '}
                                            {new Date(target.dueDate * 1000).toLocaleDateString()}
                                        </p>
                                    </MetricTarget>
                                </>
                            ) : (
                                <MetricNoTarget>Add a target to track your progress</MetricNoTarget>
                            )}
                        </MetricProgress>
                    </FlexWrapper>
                ) : (
                    <LineChart
                        name={name}
                        unit={''}
                        values={renderValues}
                        target={target}
                        line={displayMode !== 'SINGLE'}
                    />
                )}
            </FlexWrapper>
        </Card>
    );
};

export default Widget;
